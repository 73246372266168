import { useState } from "react";
import { FormButton } from "../../../../components/organisms/Form";
import { Button } from "../../../../components/atom/button";
import {
  useGenerateInventoryVideoMutation,
  useResetInventoryMutation,
  useSyncInventoryToFinnMutation,
  useUpdateInventoryBicycleMutation,
  useUpdateInventoryMutation,
  useUpdateInventorySkiMutation,
} from "@app/graphql/pim";
import Notification from "../../../../components/molecules/Notification";
import { VideoCameraIcon } from "@heroicons/react/24/solid";

import LoadingInline from "../../../../components/molecules/LoadingInline";

type SaveButtonsProps = {
  id: string;
  data: any;
  callback: () => void;
  skiData?: any | undefined;
  bicycleData?: any | undefined;
  edited?: Date | null | undefined;
};

export function SaveButtons({
  id,
  data,
  callback,
  skiData,
  bicycleData,
  edited,
}: SaveButtonsProps) {
  const [update] = useUpdateInventoryMutation();
  const [updateSki] = useUpdateInventorySkiMutation();
  const [updateBicycle] = useUpdateInventoryBicycleMutation();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const handleUpdate = async () => {
    if (loading) return;

    try {
      setLoading(true);
      setError(null);
      setShowError(false);

      if (skiData) {
        await updateSki({
          variables: {
            patch: {
              ...skiData,
              length: parseInt(skiData["length"]),
            },
            id: id,
          },
        });
      }
      if (bicycleData) {
        console.log(bicycleData);
        await updateBicycle({
          variables: {
            patch: {
              ...bicycleData,
            },
            id: id,
          },
        });
      }

      await update({
        variables: {
          patch: {
            ...data,
            price: parseInt(data["price"]),
            score: parseInt(data["score"]),
          },
          id: id,
        },
      });
      callback();
      setShowSuccess(true);
    } catch (e: any) {
      setError(`${e.message}`);
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Notification
        show={showSuccess}
        setShow={setShowSuccess}
        type="success"
        title="Lagret"
        message="Endringene er lagret"
        autoClose
        autoCloseDuration={3000} // 3 seconds
      />
      {/* Info notification */}
      <Notification
        show={showError}
        setShow={setShowError}
        type="error"
        title="Kunne ikke lagre"
        message={error || "Noe gikk galt"}
        // No autoClose here—user has to manually dismiss
      />
      <Button
        className="w-16 h-9"
        color="green"
        disabled={edited === null || loading}
        onClick={handleUpdate}
      >
        {loading ? (
          <LoadingInline className="border-bg-white w-5 h-5" />
        ) : (
          <>Lagre</>
        )}
      </Button>
    </>
  );
}

type ResetButtonProps = {
  id: string;
  callback: () => void;
};

export function ResetButton({ id, callback }: ResetButtonProps) {
  const [reset] = useResetInventoryMutation();
  const [loading, setLoading] = useState(false);
  const handleReset = async () => {
    if (loading) return;
    if (!window.confirm("Are you sure you want to reset?")) return;

    try {
      setLoading(true);

      await reset({
        variables: {
          inventoryId: id,
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  return <FormButton type="reset" onClick={handleReset} />;
}

export function GenerateVideoButton({ inventoryId }: { inventoryId: string }) {
  const [generateVideo] = useGenerateInventoryVideoMutation({
    variables: {
      inventoryId,
    },
  });
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await generateVideo();
    setLoading(false);
  };

  return (
    <div className="flex space-x-2 py-4 justify-start items-center">
      {loading ? (
        <LoadingInline />
      ) : (
        <Button onClick={() => handleClick()}>
          <VideoCameraIcon className="h-5 w-5 mr-2" aria-hidden="true" />
          Generate Video
        </Button>
      )}
    </div>
  );
}

export function SyncToFinnButton({ inventoryId }: { inventoryId: string }) {
  const [syncToFinn] = useSyncInventoryToFinnMutation({
    variables: {
      inventoryId,
    },
  });
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await syncToFinn();
    setLoading(false);
  };

  return (
    <div className="flex space-x-2 py-4 justify-start items-center">
      {loading ? (
        <LoadingInline />
      ) : (
        <Button onClick={() => handleClick()}>Sync til finn.no</Button>
      )}
    </div>
  );
}
