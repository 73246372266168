import {
  Inventory,
  InventoryStatus,
  usePimInventoriesQuery,
  usePimUserQuery,
} from "@app/graphql/pim";
import { useAuth0 } from "@auth0/auth0-react";
import {
  BellAlertIcon,
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { authedFetch } from "../../utils/rest";
import LoadingInline from "./LoadingInline";
import { Subheading } from "../atom/heading";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../atom/table";
import Popup from "./Popup";
type Bid = {
  bid: number;
  id: string;
  serverTimestamp: string;
  userTimestamp: string;
  user: string;
  ip: string;
  initials: string;
  color: string;
};

type AuctionInfo = {
  active: boolean;
  auction_start: string;
  auction_end: string;
  current_bid: number;
  current_bid_time: string;
  auction_start_price: number;
  next_minimum_bid: number;
  numberOfBids: number;
  current_bid_user: string;
  current_bid_user_phone: string;
  remainingTime: number;
  bids: Bid[];
};

const formatCurrency = (value: number, locale: string) =>
  new Intl.NumberFormat("NO-no", {
    style: "currency",
    currency: "NOK",
    maximumFractionDigits: 0,
  }).format(value);

export function UserName({ user }: { user: string }) {
  const { data, loading } = usePimUserQuery({
    variables: { id: user },
  });
  if (loading)
    return <span className="text-sm font-medium leading-6 ">{user}</span>;
  return (
    <span className="text-sm font-medium leading-6 ">
      {data?.user?.name || user}{" "}
      {data?.user?.email && (
        <span className="text-xs font-light">({data?.user?.email})</span>
      )}
    </span>
  );
}

export function UserInfo({ user }: { user: string }) {
  const { data, loading } = usePimUserQuery({
    variables: { id: user },
  });
  if (loading)
    return (
      <>
        <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
          <dt className="flex-none">
            <span className="sr-only">Client</span>
            <LoadingInline />
          </dt>
          <dd className="text-sm font-medium leading-6 ">{user}</dd>
        </div>
      </>
    );
  return (
    <>
      <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
        <dt className="flex-none">
          <span className="sr-only">Client</span>
          <UserCircleIcon
            className="h-6 w-5 text-gray-400"
            aria-hidden="true"
          />
        </dt>
        <dd className="text-sm font-medium leading-6 ">
          {data?.user?.name || user}
        </dd>
      </div>
      {data?.user?.email && (
        <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
          <dt className="flex-none">
            <span className="sr-only">Status</span>
            <EnvelopeIcon
              className="h-6 w-5 text-gray-400"
              aria-hidden="true"
            />
          </dt>
          <dd className="text-sm leading-6 text-gray-500">
            <a href={`mailto: ${data?.user?.email}`}>{data?.user?.email}</a>{" "}
          </dd>
        </div>
      )}
      {data?.user?.phone && (
        <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
          <dt className="flex-none">
            <span className="sr-only">Status</span>
            <DevicePhoneMobileIcon
              className="h-6 w-5 text-gray-400"
              aria-hidden="true"
            />
          </dt>
          <dd className="text-sm leading-6 text-gray-500">
            {data?.user?.phone}
          </dd>
        </div>
      )}
    </>
  );
}
{
}

export function BidInfo({
  auctionInfo,
  inventory,
  children,
}: {
  auctionInfo: AuctionInfo;
  inventory: Inventory;
  children: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button type="button" className="underline" onClick={() => setOpen(true)}>
        {children}
      </button>
      <Popup open={open} setOpen={setOpen} title={`Gjeldende bud`}>
        <div className="lg:col-start-3 lg:row-end-1">
          <h2 className="sr-only">Summary</h2>
          <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
            <dl className="flex flex-wrap pb-6">
              <div className="flex-auto pl-6 pt-6">
                <dt className="text-sm font-semibold leading-6 ">
                  {inventory.name}
                </dt>
                <dd className="mt-1 text-base font-semibold leading-6 ">
                  {formatCurrency(auctionInfo.current_bid, "no")}
                </dd>
              </div>
              <div className="flex-auto pl-6 pt-6">
                <dt className="text-sm font-semibold leading-6 ">
                  <span className="sr-only">Bid time</span>
                </dt>
                <dd className="text-sm leading-6 text-gray-500">
                  <time dateTime={auctionInfo.current_bid_time}>
                    {moment(auctionInfo.current_bid_time).format(
                      "DD/MM-YYYY HH:mm"
                    )}{" "}
                  </time>
                </dd>
              </div>
              <UserInfo user={auctionInfo.current_bid_user} />
              <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                <dt className="flex-none">
                  <span className="sr-only">Status</span>
                  <BellAlertIcon
                    className="h-6 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </dt>
                <dd className="text-sm leading-6 text-gray-500">
                  {auctionInfo.current_bid_user_phone}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </Popup>
    </>
  );
}

export function BidListPopup({ auctionInfo }: { auctionInfo: AuctionInfo }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <button
        type="button"
        className="text-indigo-600 hover:text-indigo-900"
        onClick={() => setOpen(true)}
      >
        {auctionInfo.numberOfBids} bud
      </button>
      <Popup open={open} setOpen={setOpen} title={`Bud`}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader>Name</TableHeader>
              <TableHeader>Bud</TableHeader>
              <TableHeader>Tid</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {auctionInfo.bids.map((bid) => (
              <TableRow key={bid.id}>
                <TableCell>
                  <UserName user={bid.user} />
                </TableCell>
                <TableCell>{formatCurrency(bid.bid, "no")}</TableCell>
                <TableCell>
                  <time dateTime={bid.serverTimestamp}>
                    {moment(bid.serverTimestamp).format("DD/MM HH:mm")}
                  </time>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Popup>
    </>
  );
}

function AuctionStat({ inventory }: { inventory: Inventory }) {
  const id = inventory.id;
  const [auctionInfo, setAuctionInfo] = useState({
    active: false,
    auction_start: "",
    auction_end: "",
    current_bid: 0,
    current_bid_time: "",
    auction_start_price: 0,
    numberOfBids: 0,
    next_minimum_bid: 0,
    current_bid_user: "",
    current_bid_user_phone: "",
    remainingTime: 0,
    bids: [],
  } as AuctionInfo);
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const auctionInfoURL = `${process.env.REACT_APP_REST_API_BASE_URL}/auction/${id}`;
  const active = auctionInfo.active;
  const ended =
    !active &&
    auctionInfo?.auction_end &&
    moment(auctionInfo.auction_start).isAfter(moment());

  // timer to refresh auction info

  useEffect(() => {
    const getData = async () => {
      try {
        const auctionFetc = await authedFetch(
          auctionInfoURL,
          {
            method: "GET",
          },
          getAccessTokenSilently
        );
        const info = await auctionFetc.json();
        setAuctionInfo(info);
        setLoading(false);
      } catch (err) {
        console.log(err);
        set: setLoading(false);
      }
    };
    getData();
  }, [id]);

  const current_bid_time_relative = moment(
    auctionInfo.current_bid_time
  ).fromNow();

  return (
    <TableRow>
      <TableCell>
        <Link to={`inventory/${id}`} className="hover:underline">
          {inventory.name}
        </Link>
      </TableCell>
      <TableCell>
        {auctionInfo.auction_start &&
          moment(auctionInfo.auction_start).format("DD/MM HH:mm")}
      </TableCell>
      <TableCell>
        {auctionInfo.auction_end &&
          moment(auctionInfo.auction_end).format("DD/MM HH:mm")}
      </TableCell>

      <TableCell>
        {inventory.auctionStartPrice &&
          formatCurrency(inventory.auctionStartPrice, "no")}
      </TableCell>
      <TableCell>
        {inventory?.bicycleIntakeForm?.bicyclePriceQuery?.offeredPrice &&
          formatCurrency(
            inventory.bicycleIntakeForm.bicyclePriceQuery.offeredPrice,
            "no"
          )}
      </TableCell>
      <TableCell>
        {auctionInfo.current_bid && (
          <BidInfo auctionInfo={auctionInfo} inventory={inventory}>
            {formatCurrency(auctionInfo.current_bid, "no")},{" "}
            {current_bid_time_relative}{" "}
          </BidInfo>
        )}
      </TableCell>
      <TableCell>
        {auctionInfo.numberOfBids > 0 ? (
          <BidListPopup auctionInfo={auctionInfo} />
        ) : (
          <span className="text-gray-400">Ingen bud</span>
        )}
      </TableCell>
    </TableRow>
  );
}

export default function AuctionStats({
  open = true,
  title = "Auctions",
}: {
  open?: boolean;
  title?: string;
}) {
  const status = open ? InventoryStatus.Auction : InventoryStatus.AuctionDone;
  const { data, loading } = usePimInventoriesQuery({
    variables: { filter: { status: { equalTo: status } } },
  });
  //filter: { status: "AUCTION" },
  if (loading) return <div>Loading...</div>;
  const inventories = data?.inventories?.nodes || [];
  return (
    <>
      <Subheading>{title}</Subheading>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Navn</TableHeader>
            <TableHeader>Start</TableHeader>
            <TableHeader>Slutt</TableHeader>
            <TableHeader>Utropspris</TableHeader>
            <TableHeader>Kost</TableHeader>
            <TableHeader>Gjeldende bud</TableHeader>
            <TableHeader>
              <span className="sr-only">Edit</span>
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {inventories.map((inventory) => (
            <AuctionStat
              key={inventory.id}
              inventory={inventory as Inventory}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
}
