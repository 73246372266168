"use client";
import { Avatar } from "../atom/avatar";
import { useSearchParams } from "react-router-dom";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "../atom/dropdown";
import {
  Navbar,
  NavbarDivider,
  NavbarItem,
  NavbarLabel,
  NavbarSection,
  NavbarSpacer,
} from "../atom/navbar";
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  Cog8ToothIcon,
  LightBulbIcon,
  PlusIcon,
  ShieldCheckIcon,
  UserIcon,
} from "@heroicons/react/16/solid";
import { useState } from "react";
import FilterText from "../molecules/FilterText";
import { InboxIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";

export default function PartnerInventoryListHeader() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSearch, setShowSearch] = useState(false);
  const page = searchParams.get("p");

  const setPage = (value: string) => {
    setSearchParams({ p: value });
  };

  return (
    <Navbar className="border rounded-md pl-3 pr-2">
      <NavbarLabel className="text-sm font-semibold">Annonser</NavbarLabel>
      <NavbarDivider className="" />
      <NavbarSection className="">
        <NavbarItem
          onClick={() => setPage("default")}
          current={!page || page === "default"}
        >
          Kladder
        </NavbarItem>
        <NavbarItem
          onClick={() => setPage("active")}
          current={page === "active"}
        >
          Aktive
        </NavbarItem>
        <NavbarItem onClick={() => setPage("sold")} current={page === "sold"}>
          Solgte
        </NavbarItem>
        <NavbarItem
          onClick={() => setPage("archived")}
          current={page === "archived"}
        >
          Arkiverte
        </NavbarItem>
      </NavbarSection>
      <NavbarSpacer />
      <NavbarSection>
        {/* <NavbarItem href="/search" aria-label="Search">
          <MagnifyingGlassIcon />
        </NavbarItem> */}
        <NavbarItem href="new">
          <PlusIcon />{" "}
        </NavbarItem>
      </NavbarSection>

      {/*
      <InputGroup>
                  <MagnifyingGlassIcon />
                  <FilterText name="name" placeholder="Søk" />
                </InputGroup> */}
    </Navbar>
  );
}
