import { ProductType } from "@app/graphql";
import {
  useCreateProductMutation,
  useDeleteImageMutation,
  useProductQuery,
  useProductsQuery,
  useUpdateProductMutation,
  useUploadImageMutation,
} from "@app/graphql/pim";
import { FieldType, Form, ItemSchema, ListColumn } from "@app/types";
import { z } from "zod";
import { getOptionsFromEnum, stringToNumber } from "../utils/schema";
import addOnProduct from "./addonproduct";
export const addInitialValues = {
  productType: null,
  name: "",
  price: 0,
  vatpct: 0,
  published: false,
};

export const addForm: Form = [
  {
    id: "productType",
    name: "Produkttype",
    type: FieldType.select,
    required: true,
    values: getOptionsFromEnum(ProductType),
    zFieldType: z.nativeEnum(ProductType),
  },

  {
    id: "name",
    name: "Produktnavn",
    colspan: 4,
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "price",
    name: "Pris",
    type: FieldType.number,
    parseFormData: stringToNumber,
    required: true,
    zFieldType: z.number(),
  },
  {
    id: "vatpct",
    name: "MVA %",
    type: FieldType.number,
    parseFormData: stringToNumber,
    required: true,
    zFieldType: z.number(),
  },
];

export const editForm: Form = [
  ...addForm,
  {
    id: "image",
    name: "Bilde",
    type: FieldType.image,
    colspan: 4,
    required: false,
    zFieldType: z.string().optional(),
    fieldProps: {
      useUploadImageMutation,
      useDeleteImageMutation,
      forType: "product",
    },
  },
  {
    id: "description",
    name: "Beskrivelse",
    type: FieldType.text,
    colspan: 4,
    required: false,
    zFieldType: z.string().optional(),
  },
  {
    id: "published",
    name: "Publisert",
    colspan: 4,
    type: FieldType.boolean,
    required: false,
    zFieldType: z.boolean().optional(),
  },
];

export const editInitialValues = {
  ...addInitialValues,
  description: "",
};

export const listColumns: ListColumn[] = [
  {
    id: "name",
    name: "Produktnavn",
  },
  {
    id: "published",
    name: "Publisert",
    parseExtraData: (data) => (data.published ? "Ja" : "Nei"),
  },
  {
    id: "price",
    name: "Pris",
  },
  {
    id: "vatpct",
    name: "MVA %",
  },
  {
    id: "created",
    name: "Opprettet",
  },
  {
    id: "lastUpdated",
    name: "Oppdatert",
  },
];

const product: ItemSchema = {
  id: "product",
  name: "Produkt",
  plural: "Produkter",
  listColumns,
  addForm,
  editForm,
  addInitialValues,
  editInitialValues,
  children: [addOnProduct],
  parentId: "productId",
  parentObjectName: "product",
  useItemsQuery: useProductsQuery,
  useItemQuery: useProductQuery,
  useCreateItemMutation: useCreateProductMutation,
  useUpdateItemMutation: useUpdateProductMutation,
  parseItem: (data) => data?.product || {},
  parseItems: (data) => ({
    totalCount: data?.products?.totalCount,
    items: data?.products?.nodes,
  }),
  createInputFromValues: (values) => ({
    product: values,
  }),
};

export default product;
