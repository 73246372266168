import { Route, Routes } from "react-router-dom";

import CreatePartnerInventory, {
  WaithWhileWorkerIsGeneratingDescription,
} from "../components/organisms/CreatePartnerInventory";
import PartnerInventoryListing from "../components/organisms/ListPartnerInventory";
import InventoryDetails from "../components/organisms/EditPartnerInventory";
export default function Outlet() {
  return (
    <Routes>
      <Route index element={<PartnerInventoryListing />} />
      <Route path="new">
        <Route index element={<CreatePartnerInventory />} />
        <Route
          path=":id"
          element={<WaithWhileWorkerIsGeneratingDescription />}
        />
      </Route>
      <Route path=":id" element={<InventoryDetails showHeader={false} />} />
    </Routes>
  );
}
