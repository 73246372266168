import { classNames } from "../../utils/dom";

export default function LoadingInline({ className }: { className?: string }) {
  if (!className) {
    className = "border-gray-900 h-3 w-3 ";
  }
  return (
    <div className="flex justify-center">
      <div
        className={classNames(
          "animate-spin rounded-full border-b-2",
          className
        )}
      />
    </div>
  );
}
