import {
  useCreateModelMutation,
  useModelQuery,
  useModelsQuery,
  useReferenceBrandFamiliesLazyQuery,
  useUpdateModelMutation,
} from "@app/graphql/pim";
import {
  FieldType,
  Form,
  ItemFilter,
  ItemSchema,
  ListColumn,
} from "@app/types";
import { z } from "zod";
import bicycle from "./bicycle";

export const addInitialValues = {
  name: null,
  brandFamilyId: null,
};

export const addForm: Form = [
  {
    id: "name",
    name: "Name",
    colspan: 4,
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "brandFamilyId",
    name: "Brand Family",
    type: FieldType.reference,
    required: true,
    zFieldType: z.string(),
    fieldProps: {
      useGetSearchResultsLazyQuery: useReferenceBrandFamiliesLazyQuery,
      parseSearchResult: (data: any) => data?.brandFamilies?.nodes || [],
      parseDefaultName: (data: any) => data?.brandFamily?.name || "",
    },
  },
];

export const editForm: Form = [...addForm];

export const editInitialValues = {
  ...addInitialValues,
};

export const listColumns: ListColumn[] = [
  {
    id: "id",
    name: "ID",
  },

  {
    id: "name",
    name: "Name",
  },
  {
    id: "brandFamily",
    name: "Brand Family",
    parseFormData: (data) => data?.name,
  },
];

const filters: ItemFilter[] = [
  {
    name: "name",
    type: "text",
    subtype: "like",
  },
];

const model: ItemSchema = {
  id: "model",
  name: "Model",
  plural: "Models",
  idIsNumber: true,
  listColumns,
  addForm,
  filters,
  editForm,
  addInitialValues,
  editInitialValues,
  children: [bicycle],
  parentId: "modelId",
  parentObjectName: "model",
  useItemsQuery: useModelsQuery,
  useItemQuery: useModelQuery,
  useCreateItemMutation: useCreateModelMutation,
  useUpdateItemMutation: useUpdateModelMutation,
  parseItem: (data) => data?.model || {},
  parseItems: (data) => ({
    totalCount: data?.models?.totalCount,
    items: data?.models?.nodes,
  }),
  createInputFromValues: (values) => ({
    model: values,
  }),
};

export default model;
