import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  Inventory,
  InventoryBicycle,
  InventorySki,
  InventoryType,
  ObjectType,
  SkiStiffness,
  SkiType,
  useCreateInventoryImageMutation,
  usePimInventoryQuery,
  useRemoveInventoryImageMutation,
  useSkiBrandsQuery,
} from "@app/graphql/pim";

import ZebraDetailList from "../../../components/molecules/ZebraDetailList";
import Loading from "../../../components/molecules/Loading";

import { EyeIcon } from "@heroicons/react/24/solid";

import { bicycleTypes } from "@app/config";
import InventoryHeader from "../../../components/molecules/InventoryHeader";
import LoadingInline from "../../../components/molecules/LoadingInline";
import {
  BooleanField,
  DropDownSelector,
  EnumSelector,
  Field,
  FormSection,
  ValueSelector,
  initialFormInventory,
  initialFormInventoryBicycle,
  initialFormInventorySki,
} from "../../../components/organisms/Form";
import { ImageUpload } from "../../../components/organisms/ImageUpload";
import { useGetSizes } from "./hooks/getFilters";
import BikeNotFound from "../../../components/organisms/BikeNotFound";
import useEnum from "../../../utils/enum";
import { publicWebsite } from "../../../config";
import {
  GenerateVideoButton,
  ResetButton,
  SaveButtons,
  SyncToFinnButton,
} from "./InventoryForm/Buttons";

/* eslint-disable react/prop-types */

type StructuredDataType = { [key: string]: any };
type Condition = { key: string; name: string; nullable?: boolean | undefined };

const conditionGeneral: Condition[] = [
  { key: "conditionGeneral", name: "Generell vurdering" },
];

const conditionBicycle: Condition[] = [
  { key: "conditionCosmeticFrame", name: "Bruksmerker og ramme" },
  { key: "conditionSteeringStock", name: "Styrelager" },
  { key: "conditionCrank", name: "Kranklager" },
  { key: "conditionDrive", name: "Drev" },
  { key: "conditionCasette", name: "Kassett" },
  { key: "conditionChain", name: "Kjede" },
  { key: "conditionFrontTire", name: "Dekk foran" },
  { key: "conditionRearTire", name: "Dekk bak" },
  { key: "conditionFrontWheels", name: "Felg foran" },
  { key: "conditionRearWheels", name: "Felg bak" },
  { key: "conditionBrakes", name: "Bremser" },
];

const conditionDampers: Condition[] = [
  { key: "conditionDampersFront", name: "Dempere Foran", nullable: true },
  { key: "conditionDampersBack", name: "Dempere Bak", nullable: true },
];

const conditionMotorBattery: Condition[] = [
  { key: "conditionBattery", name: "Batteri", nullable: true },
  { key: "conditionMotor", name: "Motor", nullable: true },
];

function Conditions({
  data,
  setData,
  conditions,
}: {
  data: any;
  setData: any;
  conditions: Condition[];
}) {
  const values = [
    { name: "God stand", id: 2 },
    { name: "Meget god stand", id: 3 },
    { name: "Nytt", id: 4 },
  ];

  const nullableValues = [
    { name: "God stand", id: 2 },
    { name: "Meget god stand", id: 3 },
    { name: "Nytt", id: 4 },
    { name: "Har ikke", id: null },
  ];

  const onUpdate = (name: string) => (value: any) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <>
      {conditions.map((item, key) => (
        <ValueSelector
          value={data[item.key]}
          values={item.nullable ? nullableValues : values}
          name={item.name}
          onChange={onUpdate(item.key)}
          key={key}
          className={"mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-x-4"}
        />
      ))}
    </>
  );
}

type InventoryTypedInex = Inventory & {
  [key: string]: any;
};

type InventoryBicycleInex = InventoryBicycle & {
  [key: string]: any;
};

type InventorySkiInex = InventorySki & {
  [key: string]: any;
};

export default function InventoryDetails({
  showHeader = true,
}: {
  showHeader?: boolean;
}) {
  const params = useParams();
  const STATUS = useEnum("InventoryStatus");

  const { loading, refetch, data } = usePimInventoryQuery({
    variables: { id: params.id },
  });

  if (loading) {
    return <Loading />;
  }

  const inventory = data?.inventory as InventoryTypedInex;

  if (!inventory || !params.id) {
    return <BikeNotFound id={params.id} />;
  }

  if (loading) {
    return <Loading />;
  }

  const cost = inventory?.bicycleIntakeForm?.bicyclePriceQuery?.offeredPrice;

  const sportiendaScore =
    inventory?.bicycleIntakeForm?.bicyclePriceQuery?.calculatedSportiendaScore;
  return (
    <InventoryDetailsWithData
      inventory={inventory}
      id={params.id}
      statusValues={STATUS}
      callback={refetch}
      cost={cost}
      sportiendaScore={sportiendaScore}
      showHeader={showHeader}
    />
  );
}

function BicycleInventoryDetails({
  createFieldProps,
  setData,
  data,
}: {
  createFieldProps: (
    fieldId: string,
    fieldName: string,
    required?: boolean | undefined
  ) => any;
  setData: any;
  data: StructuredDataType;
}) {
  const { sizes } = useGetSizes();

  const onUpdate = (name: string) => (value: any) =>
    setData({
      ...data,
      [name]: value,
    });
  return (
    <>
      <Conditions data={data} setData={setData} conditions={conditionBicycle} />
      <Conditions data={data} setData={setData} conditions={conditionDampers} />

      <Conditions
        data={data}
        setData={setData}
        conditions={conditionMotorBattery}
      />
      <ValueSelector
        name="bicycleSize"
        value={data.bicycleSize}
        onChange={onUpdate("bicycleSize")}
        values={sizes}
      />
      <div className="w-40">
        <BooleanField
          name="Elsykkel?"
          value={data.electric}
          onChange={onUpdate("electric")}
        />
      </div>
      <ValueSelector
        name="bicycleType"
        value={data.bicycleType}
        values={bicycleTypes}
        onChange={onUpdate("bicycleType")}
      />
      <Field
        fieldId="mileage"
        fieldName="Hvor langt har sykkelen gått"
        required={false}
        value={data["mileage"]}
        onChange={onUpdate("mileage")}
        type="number"
      />

      <Field
        fieldId="engineManufacturer"
        fieldName="Motorprodusent"
        required={false}
        value={data["engineManufacturer"]}
        onChange={onUpdate("engineManufacturer")}
        type="text"
      />
      <Field
        fieldId="motorNewtonmeter"
        fieldName="Motor newtonmeter"
        required={false}
        value={data["motorNewtonmeter"]}
        onChange={onUpdate("motorNewtonmeter")}
        type="number"
      />
      <Field
        fieldId="batteryManufacturer"
        fieldName="Batteriprodusent og størrelse (kwh)"
        value={data["batteryManufacturer"]}
        required={false}
        onChange={onUpdate("batteryManufacturer")}
        type="text"
      />
      <Field
        fieldId="batteryDiagnoseSummary"
        fieldName="Batteridiagnose"
        value={data["batteryDiagnoseSummary"]}
        required={false}
        onChange={onUpdate("batteryDiagnoseSummary")}
        type="textarea"
      />
      <Field
        fieldId="riderHeightFrom"
        fieldName="Passer for rytter fra høyde (cm)"
        value={data["riderHeightFrom"]}
        required={false}
        onChange={onUpdate("riderHeightFrom")}
        type="number"
      />
      <Field
        fieldId="riderHeightTo"
        fieldName="Passer for rytter til høyde (cm)"
        value={data["riderHeightTo"]}
        required={false}
        onChange={onUpdate("riderHeightTo")}
        type="number"
      />
    </>
  );
}

function SkiInventoryDetails({
  createFieldProps,
  setData,
  data,
}: {
  createFieldProps: (
    fieldId: string,
    fieldName: string,
    required?: boolean | undefined
  ) => any;
  setData: any;
  data: StructuredDataType;
}) {
  const { data: brandsData, loading } = useSkiBrandsQuery();
  const skiBrands = brandsData?.brands?.nodes || [];
  if (loading) {
    return <LoadingInline />;
  }
  const onUpdate = (name: string) => (value: any) =>
    setData({
      ...data,
      [name]: value,
    });
  return (
    <>
      <DropDownSelector
        name="Brand"
        value={data?.brandId}
        values={skiBrands}
        onChange={onUpdate("brandId")}
      />

      <EnumSelector
        name="Ski Type"
        value={data?.skiType}
        enumValues={SkiType}
        onChange={onUpdate("skiType")}
      />
      <Field
        type="number"
        fieldId="length"
        fieldName="Lengde"
        value={data?.length}
        onChange={onUpdate("length")}
        required={true}
      />
      <BooleanField
        name="Skins?"
        value={data?.skins}
        onChange={onUpdate("skins")}
      />

      <EnumSelector
        name="Stivhet"
        value={data?.stiffness}
        enumValues={SkiStiffness}
        onChange={onUpdate("stiffness")}
      />
    </>
  );
}

type InventoryDetailsWithDataProps = {
  inventory: InventoryTypedInex;
  id: string;
  callback: any;
  statusValues: any;
  cost?: number | undefined | null;
  sportiendaScore?: number | undefined | null;
  showHeader?: boolean;
};

function InventoryDetailsWithData({
  inventory,
  id,
  callback,
  statusValues,
  cost,
  sportiendaScore,
  showHeader = true,
}: InventoryDetailsWithDataProps) {
  const params = useParams();
  const [data, setData] = useState<StructuredDataType>({
    ...initialFormInventory,
  });
  const [skiData, setSkiData] = useState<StructuredDataType>({
    ...initialFormInventorySki,
  });
  const [bicycleData, setBicycleData] = useState<StructuredDataType>({
    ...initialFormInventoryBicycle,
  });

  const [imageLoading, setLoading] = useState(false);
  const [uploadFile] = useCreateInventoryImageMutation();
  const [deleteFile] = useRemoveInventoryImageMutation();

  const calculateScore = () => {
    const allData = { ...data, ...bicycleData };
    const condtions =
      inventory.objectType === ObjectType.Bicycle &&
      inventory.type === InventoryType.Certified
        ? [...conditionGeneral, ...conditionBicycle]
        : conditionGeneral;
    const score = condtions.reduce((total, item) => {
      return total + (allData[item.key] ? allData[item.key] : 0);
    }, 0);
    setData((data) => ({ ...data, score: score }));
  };

  useEffect(() => {
    Object.entries(initialFormInventory).forEach(([key]) => {
      const structuredData: StructuredDataType = {};
      if (key in inventory) {
        structuredData[key] = inventory[key];
      }
      setData((data) => ({ ...data, ...structuredData }));
    });
    const inventorySki = inventory.inventorySkiById as InventorySkiInex;
    if (inventory.inventorySkiById && inventory.objectType === "SKI") {
      Object.entries(initialFormInventorySki).forEach(([key]) => {
        const structuredData: StructuredDataType = {};

        if (inventory.inventorySkiById && key in inventory.inventorySkiById) {
          structuredData[key] = inventorySki[key];
        }
        setSkiData((skiData) => ({ ...skiData, ...structuredData }));
      });
    }

    if (
      inventory.inventoryBicycleById &&
      inventory.objectType === ObjectType.Bicycle
    ) {
      const inventoryBicycle =
        inventory.inventoryBicycleById as InventoryBicycleInex;
      Object.entries(initialFormInventoryBicycle).forEach(([key]) => {
        const structuredData: StructuredDataType = {};

        if (
          inventory.inventoryBicycleById &&
          key in inventory.inventoryBicycleById
        ) {
          structuredData[key] = inventoryBicycle[key];
        }
        setBicycleData((bicycleData) => ({
          ...bicycleData,
          ...structuredData,
        }));
      });
    }
  }, [inventory]);

  useEffect(() => {
    if (inventory.type === "USERAD") {
      return;
    }
    calculateScore();
  }, [bicycleData, data.conditionGeneral, inventory.type]);

  const images = inventory?.inventoryImages?.nodes || [];

  const initialImages = images.map((i) => ({
    id: i.id,
    file: null,
    preview: `https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/${i.id}/public`,
  }));

  const onImageChange = async (images: [any]) => {
    try {
      setLoading(true);

      for (const img of images) {
        try {
          const result = await uploadFile({
            variables: {
              inventoryUuid: params.id,
              contentType: img.file.type,
            },
          });

          const uploadUrl = result?.data?.createInventoryImage?.uploadURL;

          if (uploadUrl) {
            let formData = new FormData();
            formData.append("file", img.file);
            await fetch(uploadUrl, {
              method: "POST",
              body: formData,
            });
          } else {
            alert("Failed to upload. No upload URL found.");
          }
        } catch (e) {
          // TODO: Error message
          console.error(e);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const onImageRemove = async (image: any) => {
    if (image.id) {
      if (window.confirm("Are you sure you want to delete this image?")) {
        await deleteFile({
          variables: {
            id: image.id,
          },
        });
      }
    }
  };

  const onUpdate = (name: string) => (value: any) =>
    setData({
      ...data,
      [name]: value,
    });

  const createFieldProps = (
    fieldId: string,
    fieldName: string,
    required?: boolean | undefined
  ) => ({
    fieldId,
    fieldName,
    value: data[fieldId],
    onChange: onUpdate(fieldId),
    required:
      required === null || typeof required === "undefined" || required
        ? true
        : false,
  });

  const cleanData = () => {
    const checkArr = [...conditionDampers, ...conditionMotorBattery].map(
      (obj) => obj.key
    );

    const result = Object.entries(data)
      .map(([key, value], index) => {
        return [key, checkArr.includes(key) && value === "" ? null : value];
      })
      .reduce((x, arr) => ({ ...x, [arr[0] as any]: arr[1] }), {});

    return result;
  };

  return (
    <>
      {showHeader && (
        <InventoryHeader
          name={inventory?.name || undefined}
          bicyclePriceQuery={
            inventory?.bicycleIntakeForm?.bicyclePriceQuery || undefined
          }
          bicycleIntakeForm={inventory?.bicycleIntakeForm || undefined}
          inventory={inventory}
          order={inventory?.order || undefined}
          refetch={callback || (() => {})}
        />
      )}
      <div className="my-5 mx-4 grid grid-flow-row grid-cols-1 gap-y-4">
        <FormSection title="Beskrivelse">
          <Field {...createFieldProps("name", "Tittel", true)} type="text" />

          <Field
            {...createFieldProps("description", "Description", true)}
            type="textarea"
            rows={25}
          />

          <Field
            {...createFieldProps("details", "Spesifikasjoner", true)}
            type="textarea"
            rows={25}
          />
        </FormSection>
        <FormSection title="Mekaniker">
          <Field
            {...createFieldProps("mechanicName", "Mekaniker fornavn", true)}
            type="text"
          />

          <Field
            {...createFieldProps("mechanicsNotes", "Mekanikers notater")}
            type="textarea"
            rows={25}
          />
          <Field
            {...createFieldProps(
              "repairCost",
              "Reparasjonskostnad (ihht faktura)"
            )}
            type="number"
            required={false}
          />
          <Conditions
            data={data}
            setData={setData}
            conditions={conditionGeneral}
          />
        </FormSection>
        {inventory.objectType === "BICYCLE" && (
          <FormSection title="Sykkel">
            <BicycleInventoryDetails
              createFieldProps={createFieldProps}
              setData={setBicycleData}
              data={bicycleData}
            />
          </FormSection>
        )}
        {inventory.objectType === "SKI" && (
          <FormSection title="Ski">
            <SkiInventoryDetails
              createFieldProps={createFieldProps}
              setData={setSkiData}
              data={skiData}
            />
          </FormSection>
        )}

        <FormSection title="Finn.no">
          <ZebraDetailList
            inline
            rows={[
              [
                "Finn id",
                inventory.finnCode && (
                  <a
                    className={"text-oxford-blue underline"}
                    target="_blank"
                    href={`https://www.finn.no/bap/webstore/ad.html?finnkode=${inventory.finnCode}`}
                  >
                    {inventory.finnCode}
                  </a>
                ),
              ],
              ["Sist syncet", inventory.finnLastSync],
              ["Bilder syncet", inventory.finnImagesUploaded],
            ]}
          />
          <SyncToFinnButton inventoryId={inventory?.id} />
        </FormSection>

        <FormSection title="Video">
          <Field
            {...createFieldProps("videoDescription", "Video Description", true)}
            type="textarea"
            rows={5}
          />
          <GenerateVideoButton inventoryId={inventory?.id} />
        </FormSection>
        <FormSection title="Bilder">
          <ImageUpload
            label={null}
            imageList={initialImages}
            onChange={onImageChange}
            onRemoved={onImageRemove}
          />
        </FormSection>
        <FormSection title="Auksjon">
          <Field
            {...createFieldProps("auctionStart", "Auksjon start")}
            type="datetime-local"
            required={false}
          />
          <Field
            {...createFieldProps("auctionEnd", "Auksjon slutt")}
            type="datetime-local"
            required={false}
          />
          <Field
            {...createFieldProps("auctionStartPrice", "Auksjon utropspris")}
            type="number"
            required={false}
          />
        </FormSection>

        <FormSection title="Status">
          {["SOLD", "AUCTION_DONE"].includes(data.status) ? (
            <>
              <Field
                {...createFieldProps("status", "Status", false)}
                disabled={true}
                type="text"
              />
              <div className="mt-4">
                <ResetButton id={id} callback={callback} />
              </div>
            </>
          ) : (
            <ValueSelector
              value={data.status}
              onChange={onUpdate("status")}
              name="Status"
              values={statusValues}
            />
          )}
        </FormSection>
        <FormSection
          title="Pris og salgsdetaljer"
          gridCols={2}
          buttons={
            <SaveButtons
              data={cleanData()}
              skiData={inventory.objectType === ObjectType.Ski && skiData}
              bicycleData={
                inventory.objectType === ObjectType.Bicycle && bicycleData
              }
              id={id}
              callback={callback}
            />
          }
        >
          <Field {...createFieldProps("price", "Pris", true)} type="number" />
          <Field
            fieldId="cost"
            fieldName="Cost"
            value={cost}
            required={false}
            type="number"
            disabled={true}
          />
          <Field
            {...createFieldProps("priceNew", "Nypris", true)}
            type="number"
          />
          <Field
            fieldId="originalPrice"
            fieldName="Bicycle Original Price"
            value={data?.originalPrice}
            required={false}
            type="number"
            disabled={true}
          />
          <Field
            {...createFieldProps("score", "Sales Score", true)}
            type="number"
          />
          <Field
            fieldId="sportienda_score"
            fieldName="Sportienda score"
            value={sportiendaScore}
            required={false}
            type="number"
            disabled={true}
          />
        </FormSection>

        {["FOR_SALE", "FOR_SALE_HIDDEN", "AUCTION", "AUCTION_DONE"].includes(
          data.status
        ) && (
          <Link
            className="fixed bottom-4 right-4 inline-flex items-center rounded-full border border-transparent bg-sportgreen p-3 text-white shadow-sm hover:bg-spanish-green focus:outline-none focus:ring-2 focus:spanish-green focus:ring-offset-2"
            to={`${publicWebsite}/s/${inventory?.id}`}
          >
            <EyeIcon className="h-6 w-6" aria-hidden="true" />
          </Link>
        )}
      </div>
    </>
  );
}
