import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
} from "@heroicons/react/16/solid";

import { Avatar } from "../atom/avatar";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
  DropdownHeader,
} from "../atom/dropdown";

import { useAuth0 } from "@auth0/auth0-react";
import { classNames } from "../../utils/dom";

function UserInfo({ user }: { user: any }) {
  return (
    <>
      {user["https://sportienda.com/nickname"] && (
        <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
          {user["https://sportienda.com/nickname"]}
        </span>
      )}
      <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
        {user.email}
      </span>
    </>
  );
}

export default function UserDropdown({
  as,
  small = false,
  Icon = ChevronDownIcon,
}: {
  as: any;
  small?: boolean;
  Icon?: any;
}) {
  const { user, logout } = useAuth0();
  if (!user) return null;
  return (
    <Dropdown>
      <DropdownButton as={as}>
        <span className="flex min-w-0 items-center gap-3">
          {user["https://sportienda.com/picture"] && (
            <Avatar
              src={user["https://sportienda.com/picture"]}
              className="size-10 max-lg:size-8"
              square={!small}
              alt=""
            />
          )}

          <span className={classNames("min-w-0", small ? "max-lg:hidden" : "")}>
            <UserInfo user={user} />
          </span>
        </span>
        <Icon />
      </DropdownButton>
      <DropdownMenu className="min-w-64" anchor="top start">
        {small && (
          <DropdownHeader>
            <UserInfo user={user} />
          </DropdownHeader>
        )}

        <DropdownItem
          onClick={() => {
            logout();
          }}
        >
          <ArrowRightStartOnRectangleIcon />
          <DropdownLabel>Logg ut</DropdownLabel>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
