// import type { z } from "zod";

export type User = {
  id?: string;
  email: string;
  first_name: string;
  last_name: string;
  phone?: string;
  address?: string;
  postal_code?: string;
  postal_place?: string;
  fiken_user_id?: number;
};

export type Inventory = {
  name: string;
};
export type Order = {
  id: string;
  email?: string;
  shipping_first_name?: string;
  shipping_last_name?: string;
  phone_number?: string;
};

export type BicyclePriceQuery = {
  bicycle_name: string;
  account_number: string;
  offered_price: number;
  club_code?: string;
};

export type InventoryShipping = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
};

export type SendTemplateEmailPayload = {
  toEmail: string;
  templateId: number;
  params: any;
  subject?: string | undefined;
  attachment?: any;
};

export type SendEmailPayload = {
  toEmail: string;
  subject: string;
  body: string;
};

export type ListDescription = {
  id: number;
  name: string;
  description: string;
};

export type Option = {
  id: string | number | null;
  name: string;
  disabled?: boolean;
};

export type SchemaEntry = [
  string,
  string,
  Option[] | any,
  (boolean | undefined)?,
  (any | undefined)?
];

export type SummaryEntry = {
  name: string;
  rows: [string, any][];
};

export type Filter = {
  [key: string]: { in: string[] } | { equalTo: boolean };
};

export interface Bid {
  id: string;
  ip: string;
  bid: number;
  userTimestamp: string;
  serverTimestamp: string;
  user: string;
  initials: string;
  avatar: string;
}

export enum FieldType {
  select = "select",
  number = "number",
  boolean = "boolean",
  text = "text",
  textarea = "textarea",
  image = "image",
  reference = "reference",
  label = "label",
  component = "component",
  datetime = "datetime",
  email = "email",
  phone = "phone",
  file = "file",
}

export type FormFieldComponentProps = {
  id: string;
  name: string;
  data: any;
  refetch?: () => void;
};

export type Form =
  | {
      id: string;
      name: string;
      type: FieldType;
      values?: Option[];
      nullable?: boolean;
      zFieldType?: any; //z.ZodType<any>;
      colspan?: number;
      required: boolean;
      autocomplete?: string;
      description?: string;
      info?: string | ((data: any) => string);
      parseFormData?: (value: any) => any;
      fieldProps?: any;
    }[]
  | null
  | undefined;

export type ListColumn = {
  id: string;
  name: string;
  sortBy?: string;
  parseFormData?: (value: any) => any;
  parseExtraData?: (data: any) => any;
};

export type ItemFilter = {
  name: string;
  type: "enum" | "text";
  subtype?: "like";
  enum?: any;
  placeholder?: string;
};

export type ItemSchema = {
  id: any;
  name: string;
  plural: string;
  idIsNumber?: boolean;
  listColumns: ListColumn[];
  addForm: Form;
  editForm: Form;
  children?: ItemSchema[];
  parentId?: string;
  parentObjectName?: string;
  parentIdValue?: any;
  defaultOrderBy?: string;
  filters?: ItemFilter[];
  parseItem: (data: any) => any;
  parseItems: (data: any) => { totalCount: number; items: any[] };
  createInputFromValues: (data: any) => any;
  createAddInputFromValues?: (data: any) => any;
  addInitialValues: Record<string, any>;
  editInitialValues: Record<string, any>;
  useItemQuery: any;
  useItemsQuery: any;
  useCreateItemMutation: any;
  useUpdateItemMutation: any;
};

export type NextItemSchema<Item> = {
  id: any;
  name: string;
  plural: string;
  listColumns: ListColumn[];
  addForm: Form;
  editForm: Form;
  addInitialState: Record<string, any>;
  editInitialState: Record<string, any>;
  defaultOrderBy?: string;
  filters?: ItemFilter[];
  getItem: (id: any) => Promise<Item | null>;
  getItems?: (filters?: ItemFilter[], limit?: number) => Promise<Item[]>;
  editFormHandler: any;
  addFormHandler: any;
};

export type FeaturedCategory = {
  id: string;
  name: string;
  href: string;
  className?: string;
  imageSrc?: string;
};

export type NavCategory = {
  id: string;
  name: string;
  featured: FeaturedCategory[] | undefined;
};

export type Page = {
  name: string;
  href: string;
  className?: string;
};

export type Navigation = {
  categories: NavCategory[];
  pages: Page[];
  mainNav: Page[];
};

export type Locale = {
  name: string;
  flag: string;
  locale: string;
};

export type Filters = {
  id: string;
  value: string;
  name: string;
};

export type SubCategory = {
  id: string;
  name: string;
  description?: string;
  filters?: Filters[];
  type?: string;
  className?: string;
  img?: string;
  filter: Filter;
  hidden?: boolean;
};

export type Category = SubCategory & {
  subCategories?: SubCategory[];
  showSubCategories: boolean;
};

export type AuctionInfo = {
  active: boolean;
  auction_start: string;
  auction_end: string;
  next_minimum_bid: number;
  numberOfBids: number;
  remainingTime: number;
};

export interface Bid {
  id: string;
  ip: string;
  bid: number;
  userTimestamp: string;
  serverTimestamp: string;
  user: string;
  initials: string;
  avatar: string;
  you_won?: boolean;
}

export interface WinnerBid {
  bid: number;
  you_won: boolean;
  initials: string;
  avatar: string;
}

export interface Service {
  id: string;
  name: string;
  productId?: string;
  description?: string;
  bookingId?: string;
  image?: string;
}

export interface ChatMessage {
  id: string;
  from_user_initials: string;
  from_user_avatar: string;
  from_user_id: string;
  message: string;
  timestamp: string;
  read?: string | null;
}
