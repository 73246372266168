export const initialFormInventory = {
  name: null,
  description: null,

  details: null,
  score: null,

  price: null,
  status: "DRAFT",
  mechanicName: null,
  conditionGeneral: null,

  mechanicsNotes: null,
  videoDescription: null,
  repairCost: null,
  auctionStart: null,
  auctionEnd: null,
  auctionStartPrice: null,
  priceNew: null,
};

export const initialFormInventoryBicycle = {
  electric: false,
  bicycleType: null,
  bicycleSize: null,

  conditionCosmeticFrame: null,
  conditionSteeringStock: null,
  conditionDrive: null,
  conditionCasette: null,
  conditionCrank: null,
  conditionChain: null,
  conditionFrontTire: null,
  conditionRearTire: null,
  conditionFrontWheels: null,
  conditionRearWheels: null,
  conditionBrakes: null,
  conditionDampersFront: null,
  conditionDampersBack: null,
  conditionBattery: null,
  conditionMotor: null,
  mileage: null,
  engineManufacturer: null,
  batteryManufacturer: null,
  spokesId: null,
  riderHeightTo: null,
  riderHeightFrom: null,
  motorNewtonmeter: null,
  batteryDiagnoseSummary: null,
  frameNumber: null,
};

export const initialFormInventorySki = {
  brandId: null,
  skiType: null,
  length: null,
  stiffness: null,
  skins: null,
};
