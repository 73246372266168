import DevInfo from "../molecules/DevInfo";
import { useState, useEffect } from "react";
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cog8ToothIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/16/solid";
import {
  HomeIcon,
  QuestionMarkCircleIcon,
  ArrowLeftEndOnRectangleIcon,
  ArrowPathRoundedSquareIcon,
  CalendarIcon,
  ArrowTopRightOnSquareIcon,
  DocumentTextIcon,
  LockClosedIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
} from "@heroicons/react/20/solid";
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from "../atom/sidebar";
import { useAuth0 } from "@auth0/auth0-react";
import { TableCellsIcon } from "@heroicons/react/24/solid";
import config from "../../config";
import UserDropdown from "./UserDropdown";
import OrgDropdown from "./OrgDropdown";
import CommandPallet from "../molecules/CommandPallet";

const SPORT = config.org;

export const parterSidebarNavigation = [
  {
    name: "Home",
    href: "",
    exact: false,
    icon: HomeIcon,
    current: true,
  },
  {
    name: "Selg",
    href: "partner",
    exact: false,
    icon: ArrowTopRightOnSquareIcon,
    current: false,
  },

  {
    name: "Innbytte",
    href: "tradein",
    exact: false,
    icon: ArrowPathRoundedSquareIcon,
    current: false,
  },
];
const sportiendaPimNavigation = [
  {
    name: "Trello",
    href: "internal/trello",
    exact: true,
    icon: CalendarIcon,
    current: false,
  },
  {
    name: "Prisforespørsler",
    href: "internal/pricequery",
    exact: false,
    icon: QuestionMarkCircleIcon,
    current: false,
  },
  {
    name: "Inntak",
    href: "internal/intake",
    exact: false,
    icon: ArrowLeftEndOnRectangleIcon,
    org_id: SPORT,
    current: false,
  },
  {
    name: "Salgsplakater",
    href: "internal/inventory",
    exact: false,
    icon: ShoppingCartIcon,
    current: false,
  },
  {
    name: "Order",
    href: "internal/order",
    exact: false,
    icon: ShoppingBagIcon,
    current: false,
  },

  {
    name: "Bruktprotokoll",
    href: "internal/protocol",
    exact: false,
    icon: LockClosedIcon,
    current: false,
  },

  {
    name: "Value/year",
    href: "internal/value-by-year",
    exact: false,
    icon: TableCellsIcon,
    current: false,
  },
  {
    name: "CMS",
    href: "internal/cms",
    exact: false,
    icon: DocumentTextIcon,
    current: false,
  },
];

export default function SportiendaSidebar() {
  const { user } = useAuth0();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.metaKey && event.key === "k") {
        event.preventDefault();
        setOpen(true);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Sidebar>
      <SidebarHeader className="max-lg:hidden">
        <SidebarHeading>Sportienda Partner</SidebarHeading>
        <OrgDropdown Label={SidebarLabel} as={SidebarItem} />
        <SidebarSection className="max-lg:hidden">
          {parterSidebarNavigation.map((item) => (
            <SidebarItem key={item.name} href={`/${item.href}`}>
              <item.icon />
              <SidebarLabel>{item.name}</SidebarLabel>
            </SidebarItem>
          ))}
        </SidebarSection>
      </SidebarHeader>
      <SidebarBody>
        {user.org_id === SPORT && (
          <SidebarSection>
            <SidebarHeading>Sportienda PIM</SidebarHeading>
            <CommandPallet open={open} setOpen={setOpen} />
            <div className="w-full flex justify-end text-sm md:ml-0 mt-2 ">
              <button
                type="button"
                className="flex items-center w-12 sm:w-72 text-left space-x-3 px-4 h-12 bg-white sm:ring-1 ring-slate-900/10 hover:ring-slate-300 focus:outline-none focus:ring-2 focus:ring-sportgreen shadow-sm rounded-lg text-slate-400"
                onClick={() => setOpen(true)}
              >
                <MagnifyingGlassIcon
                  className="flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />

                <span className="flex-auto hidden sm:block">
                  Quick search...
                </span>
                <kbd className="font-sans font-semibold dark:text-slate-500 hidden sm:block">
                  <abbr
                    title="Command"
                    className="no-underline text-slate-300 dark:text-slate-500"
                  >
                    ⌘
                  </abbr>{" "}
                  K
                </kbd>
              </button>
            </div>
            {sportiendaPimNavigation.map((item) => (
              <SidebarItem key={item.name} href={`/${item.href}`}>
                <item.icon />
                <SidebarLabel>{item.name}</SidebarLabel>
              </SidebarItem>
            ))}
          </SidebarSection>
        )}
      </SidebarBody>
      <SidebarFooter className="max-lg:hidden">
        <UserDropdown as={SidebarItem} Icon={ChevronUpIcon} />

        <DevInfo />
      </SidebarFooter>
    </Sidebar>
  );
}
