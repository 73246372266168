import {
  useUpdateUserMutation,
  useUserQuery,
  useUsersQuery,
} from "@app/graphql/pim";
import {
  FieldType,
  Form,
  ItemFilter,
  ItemSchema,
  ListColumn,
} from "@app/types";
import { z } from "zod";

export const addInitialValues = {
  email: null,
  firstName: null,
  lastName: null,
};

export const addForm: Form = [
  {
    id: "email",
    name: "Email",
    type: FieldType.email,
    colspan: 4,
    required: true,
  },
  {
    id: "firstName",
    name: "Fornavn",
    type: FieldType.text,
    colspan: 4,
    required: true,
  },
  {
    id: "lastName",
    name: "Etternavn",
    type: FieldType.text,
    colspan: 4,
    required: true,
  },
];

export const editForm: Form = [
  ...addForm,

  {
    id: "phone",
    name: "Telefon",
    type: FieldType.text,
    colspan: 4,
    required: true,
  },
  {
    id: "address",
    name: "Adresse",
    type: FieldType.text,
    colspan: 4,
    required: true,
  },
  {
    id: "postalCode",
    name: "Postnummer",
    type: FieldType.text,
    colspan: 4,
    required: true,
  },
  {
    id: "postalPlace",
    name: "Poststed",
    type: FieldType.text,
    colspan: 4,
    required: true,
  },
  {
    id: "fikenId",
    name: "Fiken id",
    colspan: 4,
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
];

export const editInitialValues = {
  ...addInitialValues,
  phone: null,
  address: null,
  postalCode: null,
  postalPlace: null,
  fikenId: null,
};

export const listColumns: ListColumn[] = [
  {
    id: "id",
    name: "Auth0 id",
  },

  {
    id: "name",
    name: "Navn",
  },

  {
    id: "fikenUserId",
    name: "Fiken Id",
  },
  {
    id: "emailVerified",
    name: "Email verifisert",
    parseFormData: (value: any) => {
      return value ? "Ja" : "Nei";
    },
  },
];

const filters: ItemFilter[] = [
  {
    name: "searchVector",
    type: "text",
  },
];

const user: ItemSchema = {
  id: "user",
  name: "Bruker",
  plural: "Brukere",
  listColumns,
  addForm: null,
  editForm,
  addInitialValues,
  editInitialValues,
  filters,
  useItemsQuery: useUsersQuery,
  useItemQuery: useUserQuery,
  useCreateItemMutation: null,
  useUpdateItemMutation: useUpdateUserMutation,
  parseItem: (data) => data?.user || {},
  parseItems: (data) => ({
    totalCount: data?.users?.totalCount,
    items: data?.users?.nodes,
  }),
  createInputFromValues: (values) => ({
    user: values,
  }),
};

export default user;
