import { gql, useMutation } from "@apollo/client";
import { ArrowUpCircleIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { FormButton } from "../../../../components/organisms/Form";

export const UPDATE = gql`
  mutation Update($patch: BicycleIntakeFormPatch!, $id: UUID!) {
    updateBicycleIntakeForm(input: { patch: $patch, id: $id }) {
      bicycleIntakeForm {
        id
        step
        status
        comment
        submittedByUserId
        serviceOrgId
        estimatedServiceCost
        conditionGeneral
        conditionCosmeticFrame
        conditionHeadset
        conditionCrank
        conditionBottomBracket
        conditionCasette
        conditionChain
        conditionTiresFront
        conditionTiresBack
        conditionBrakes
        conditionWheelsFront
        conditionWheelsBack
        conditionDampersFront
        conditionDampersBack
        conditionBattery
        conditionMotor
        breakType
        bicyclePriceQuery {
          electric
          ownerFirstName
          ownerLastName
          currentPrice
          scoreDiscount
          calculatedSportiendaScore
          frameNumber
          conditionGeneral
          conditionCosmeticFrame
          conditionHeadset
          conditionCrank
          conditionBottomBracket
          conditionCasette
          conditionChain
          conditionTires
          conditionWheels
          conditionDampersFront
          conditionDampersBack
          breakType
          conditionBattery
          conditionMotor
          bicycle {
            name
            year
          }
        }
      }
    }
  }
`;

export const SUBMIT = gql`
  mutation Submit($bicycleIntakeId: UUID!) {
    submitBicycleIntakeForm(input: { bicycleIntakeId: $bicycleIntakeId }) {
      bicycleIntakeForm {
        id
      }
    }
  }
`;

export const START_BUTTON = gql`
  mutation SaveStatus($id: UUID!, $patch: BicycleIntakeFormPatch!) {
    updateBicycleIntakeForm(input: { id: $id, patch: $patch }) {
      clientMutationId
    }
  }
`;

export const DELETE_BUTTON = gql`
  mutation DeleteIntakeForm($id: UUID!) {
    deleteBicycleIntakeForm(input: { id: $id }) {
      clientMutationId
    }
  }
`;

export function StartButton({ id, callback }) {
  const [update] = useMutation(UPDATE);
  const [loading, setLoading] = useState(false);
  const handleUpdate = () => {
    if (loading) return;
    setLoading(true);

    update({ variables: { patch: { status: "STARTED" }, id: id } }).then(
      ({ data }) => {
        setLoading(false);
        callback();
      }
    );
  };
  return <FormButton type="start" onClick={() => handleUpdate()} />;
}

export function SaveButton({ id, data, steps, callback }) {
  const [update] = useMutation(UPDATE);
  const [loading, setLoading] = useState(false);
  const handleUpdate = (changeStep) => {
    if (loading) return;
    setLoading(true);
    data["step"] = data.step + changeStep;
    data["estimatedServiceCost"] = parseFloat(data["estimatedServiceCost"]);

    update({ variables: { patch: data, id: id } }).then(({ data }) => {
      setLoading(false);
      callback();
    });
  };
  return (
    <>
      {data.step > 0 && (
        <FormButton type="previous" onClick={() => handleUpdate(-1)} />
      )}
      {data.step < steps.length - 1 && (
        <FormButton type="next" onClick={() => handleUpdate(1)} />
      )}
    </>
  );
}

export function DeleteteButton({ id, data, steps, callback }) {
  const [delMutation] = useMutation(DELETE_BUTTON);
  const [loading, setLoading] = useState(false);
  const handleUpdate = () => {
    if (loading) return;
    setLoading(true);

    delMutation({ variables: { id: id } }).then(({ data }) => {
      setLoading(false);
      callback();
    });
  };
  return <FormButton type="delete" onClick={() => handleUpdate()} />;
}

export function SubmitButton({ id, callback }) {
  const [submit] = useMutation(SUBMIT);
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    if (loading) return;
    setLoading(true);

    submit({ variables: { bicycleIntakeId: id } }).then(({ data }) => {
      setLoading(false);
      callback();
    });
  };

  return (
    <button
      type="submit"
      onClick={() => handleSubmit()}
      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sportgreen hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sportgreen"
    >
      Registrer inntak som ferdig{" "}
      <ArrowUpCircleIcon
        className="mr-2 h-5 w-5 text-white"
        aria-hidden="true"
      />
    </button>
  );
}
