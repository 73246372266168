import Form from "../components/organisms/CalculatorForm";
import { Heading } from "../components/atom/heading";
import { Divider } from "../components/atom/divider";
import { Subheading } from "../components/atom/heading";
import { ShadowBox } from "../components/atom/box";
import { StackedContent } from "../components/atom/content";
import PriceQueryList from "./internal/PriceQueries/PriceQueryList";

export default function TradeInDashboard() {
  return (
    <>
      <StackedContent>
        <Heading>Innbytte</Heading>
        <ShadowBox>
          <Subheading>Start inbytteprosess</Subheading>
          <Form type={"tradein"} />
        </ShadowBox>
        <Divider />
        <Subheading>Våre annonser</Subheading>
        <PriceQueryList type={"tradein"} />
      </StackedContent>
    </>
  );
}
