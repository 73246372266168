import { Avatar } from "../components/atom/avatar";

import { Heading, Subheading } from "../components/atom/heading";
import { StackedContent } from "../components/atom/content";
import { useAuth0 } from "@auth0/auth0-react";
import { useSportiendaOrg } from "../hooks/org";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/atom/table";
import InternalDashboard from "../components/organisms/InternalDashboard";
import { Divider } from "../components/atom/divider";
export default function Home() {
  const { user } = useAuth0();
  const { org } = useSportiendaOrg();
  if (!user || !org) {
    return null;
  }

  return (
    <StackedContent>
      <Heading>
        Hei {user["https://sportienda.com/nickname"] || "Person"} fra {org.name}
      </Heading>

      <InternalDashboard />
    </StackedContent>
  );
}
