import { gql, useQuery } from "@apollo/client";
import { usePaginationTableState } from "../../../../hooks/usePaginationTable";

const RECIPES = gql`
  query GetBicycleIntakeForms(
    $filter: BicycleIntakeFormFilter
    $pagesize: Int
    $offset: Int
    $orderBy: [BicycleIntakeFormsOrderBy!]
  ) {
    bicycleIntakeForms(
      filter: $filter
      first: $pagesize
      offset: $offset
      orderBy: $orderBy
    ) {
      nodes {
        id
        estimatedServiceCost
        conditionGeneral
        status
        created
        estimatedArrival
        sku
        bicyclePriceQuery {
          ownerFirstName
          ownerLastName

          bicycle {
            name
            year
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export function useBicycleIntakeForms(pagesize) {
  const { filter, offset, orderBy } = usePaginationTableState({
    filters: {
      name: (v) => ({ likeInsensitive: `%${v}%` }),
      status: (v) => ({ equalTo: v }),
    },
  });

  const result = useQuery(RECIPES, {
    variables: { filter, pagesize, offset, orderBy },
    nextFetchPolicy: "cache-and-network",
  });

  var intakeforms = result.data?.bicycleIntakeForms?.nodes ?? [];
  if (!intakeforms) {
    intakeforms = [];
  }

  const { totalCount = 0 } = result?.data?.bicycleIntakeForms ?? {};

  return { ...result, intakeforms, totalCount };
}
