import { useOrgQuery, type Org } from "@app/graphql/pim";
import { useAuth0 } from "@auth0/auth0-react";
import { useMemo, useEffect } from "react";

import { createContext, useContext } from "react";

type OrgContextValue = {
  loading: boolean;
  org?: Org;
};

const OrgContext = createContext<OrgContextValue>({
  loading: false,
  org: undefined,
});

export const SportiendaOrgProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user, isLoading: authLoading } = useAuth0();

  const orgId = user?.org_id;

  const { data, loading } = useOrgQuery({
    variables: { id: orgId },
    skip: !orgId,
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-only",
    notifyOnNetworkStatusChange: false,
  });
  const contextValue: OrgContextValue = useMemo<OrgContextValue>(
    () => ({
      org: data?.org as Org,
      loading,
    }),
    [data?.org, loading]
  );

  return (
    <OrgContext.Provider value={contextValue}>{children}</OrgContext.Provider>
  );
};

export const useSportiendaOrg = () => {
  return useContext(OrgContext);
};
