import React from "react";

import * as ButtonBase from "../atom/button";
import * as CheckBase from "../atom/checkbox";
import { Select } from "../atom/select";

export const Button = ({ children, ...props }) => (
  <ButtonBase.Button {...props}>{children}</ButtonBase.Button>
);

export const TextField = (props) => (
  <CheckBase.CheckboxField type="text" {...props} />
);

export const CheckField = (props) => <inp {...props} />;

export const SelectField = ({ options = [], ...props }) => (
  <Select {...props}>
    {options.map(([k, v]) => (
      <option key={String(k)} value={k || ""}>
        {v}
      </option>
    ))}
  </Select>
);
