import { Badge } from "../atom/badge";
import { Button } from "../atom/button";
import { Divider } from "../atom/divider";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../atom/dropdown";
import { Heading } from "../atom/heading";
import { Input, InputGroup } from "../atom/input";
import { Link } from "../atom/link";
import { Select } from "../atom/select";
import {
  usePimInventoriesQuery,
  type Inventory,
  InventoryStatus,
  InventoryType,
} from "@app/graphql/pim";
import {
  DescriptionListInline,
  DescriptionTermInline,
  DescriptionDetailsInline,
} from "../atom/description-list";
import { usePaginationTableState } from "../../hooks/usePaginationTable";
import { inventoryStatusColors } from "../../utils/colors";
import { createImageUrl, formatCurrency } from "../../utils/dom";
import FilterSingle, { FilterEnum } from "../molecules/FilterSingle";
import FilterText from "../molecules/FilterText";
import {
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/16/solid";

import { useAuth0 } from "@auth0/auth0-react";

function InventoryProperties({
  term,
  details,
}: {
  term?: string;
  details: string | null | undefined;
}) {
  if (!details) {
    return null;
  }
  return (
    <>
      {term && <DescriptionTermInline>{term}:</DescriptionTermInline>}
      <DescriptionDetailsInline>{details}</DescriptionDetailsInline>
    </>
  );
}
export function InventoryHeader({
  inventory,
  index,
  statusWorkflow,
}: {
  index: number;
  inventory: Inventory;
  statusWorkflow: React.ReactNode;
}) {
  const imageid = inventory?.inventoryImages?.nodes?.[0]?.id;
  const imageUrl = imageid
    ? createImageUrl(imageid)
    : "https://sportienda.com/bike.svg";
  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex gap-6 py-6">
          <div className="w-32 shrink-0">
            <Link href={inventory.id} aria-hidden="true">
              <img
                className="aspect-[3/2] rounded-lg shadow"
                src={imageUrl}
                alt=""
              />
            </Link>
          </div>
          <div className="space-y-1.5">
            <div className="text-base/6 font-semibold">
              <Link href={inventory.id}>{inventory.name}</Link>
            </div>
            <div className="text-xs/6 text-zinc-500">
              <DescriptionListInline>
                <InventoryProperties details={inventory.type} />
                <InventoryProperties details={inventory.objectType} />

                {inventory.inventoryBicycleById && (
                  <>
                    <InventoryProperties
                      details={inventory.inventoryBicycleById.bicycleType}
                    />
                    <InventoryProperties
                      details={
                        inventory.inventoryBicycleById.electric
                          ? "EL"
                          : undefined
                      }
                    />
                    <InventoryProperties
                      term="Størrelse"
                      details={inventory.inventoryBicycleById.bicycleSize}
                    />
                  </>
                )}
              </DescriptionListInline>
            </div>
            <div className="text-base/6 text-zinc-600">
              {inventory.price ? formatCurrency(inventory.price) : ""}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <Badge
            className="max-sm:hidden"
            color={inventoryStatusColors[inventory?.status || "DRAFT"]}
          >
            {inventory.status}

            {statusWorkflow}
          </Badge>
        </div>
      </div>
    </div>
  );
}
