import type { FormFieldComponentProps, Form as FormType } from "@app/types";
import { FieldType } from "@app/types";
import { useState } from "react";
import { getTranslation } from "../../utils/schema";

import {
  BooleanInput,
  ImageInput,
  Input,
  OptionsInput,
  ReferenceInput,
  Text,
} from "./CMSInputs";
import { Fieldset, FieldGroup, Legend } from "../atom/fieldset";

export default function Form({
  itemId,
  form,
  data,
  title,
  dictionary,
  loading,
  extraData,
  refetch,
  save,
  actionName,
}: {
  form: FormType;
  data: any;
  title: string;
  extraData?: any;
  dictionary: any;
  loading: boolean;
  itemId: string | null;
  refetch?: () => void;
  save?: (data: any) => void;
  actionName?: string;
}) {
  const [formData, setFormData] = useState(data);
  const update = (field: string, value: any) => {
    setFormData((prev: any) => ({ ...prev, [field]: value }));
  };

  return (
    <Fieldset>
      <Legend>{title}</Legend>
      <FieldGroup>
        {form?.map(
          ({
            id,
            name,
            type,
            values,
            required,
            colspan,
            parseFormData,
            nullable,
            autocomplete,
            description,
            fieldProps,
            info,
          }) => {
            if (type === FieldType.text) {
              return (
                <Input
                  key={name}
                  type="text"
                  name={id}
                  value={formData[id]}
                  required={required}
                  autoComplete={autocomplete}
                  parseFormData={parseFormData}
                  onChange={update}
                >
                  {getTranslation(dictionary, name)}
                </Input>
              );
            } else if (type === FieldType.number) {
              return (
                <Input
                  key={name}
                  type="number"
                  name={id}
                  value={formData[id]}
                  required={required}
                  autoComplete={autocomplete}
                  parseFormData={parseFormData}
                  onChange={update}
                >
                  {getTranslation(dictionary, name)}
                </Input>
              );
            } else if (type === FieldType.email) {
              return (
                <Input
                  key={name}
                  type="email"
                  name={id}
                  value={formData[id]}
                  required={required}
                  autoComplete={autocomplete}
                  parseFormData={parseFormData}
                  onChange={update}
                >
                  {getTranslation(dictionary, name)}
                </Input>
              );
            } else if (type === FieldType.phone) {
              return (
                <Input
                  key={name}
                  type="tel"
                  name={id}
                  value={formData[id]}
                  required={required}
                  autoComplete={autocomplete}
                  parseFormData={parseFormData}
                  onChange={update}
                >
                  {getTranslation(dictionary, name)}
                </Input>
              );
            } else if (type === FieldType.datetime) {
              return (
                <Input
                  key={name}
                  type="datetime-local"
                  name={id}
                  value={formData[id]}
                  required={required}
                  autoComplete={autocomplete}
                  parseFormData={parseFormData}
                  onChange={update}
                >
                  {getTranslation(dictionary, name)}
                </Input>
              );
            } else if (type === FieldType.boolean) {
              return (
                <BooleanInput
                  key={name}
                  name={id}
                  checked={formData[id]}
                  description={description}
                  onChange={update}
                >
                  {getTranslation(dictionary, name)}
                </BooleanInput>
              );
            } else if (type === FieldType.select) {
              return (
                <OptionsInput
                  key={name}
                  name={id}
                  value={formData[id]}
                  required={required}
                  options={values}
                  nullable={nullable}
                  onChange={update}
                >
                  {getTranslation(dictionary, name)}
                </OptionsInput>
              );
            } else if (type === FieldType.image) {
              return (
                <ImageInput
                  key={name}
                  name={id}
                  value={extraData ? extraData[id] : null}
                  required={required}
                  onChange={update}
                  itemId={itemId}
                  fieldProps={fieldProps}
                >
                  {getTranslation(dictionary, name)}
                </ImageInput>
              );
            } else if (type === FieldType.reference) {
              return (
                <ReferenceInput
                  key={name}
                  name={id}
                  value={formData[id]}
                  extraData={extraData}
                  required={required}
                  fieldProps={fieldProps}
                  onChange={update}
                >
                  {getTranslation(dictionary, name)}
                </ReferenceInput>
              );
            } else if (type === FieldType.label) {
              return (
                <Text
                  key={name}
                  value={extraData[id]}
                  parseFormData={parseFormData}
                >
                  {getTranslation(dictionary, name)}
                </Text>
              );
            } else if (type === FieldType.component) {
              const Component =
                fieldProps?.component as React.ComponentType<FormFieldComponentProps>;
              return (
                <Component
                  key={name}
                  id={id}
                  name={getTranslation(dictionary, name)}
                  data={extraData}
                  refetch={refetch}
                />
              );
            } else {
              throw Error(`Unknown field type ${type}`);
            }
          }
        )}
        {save && (
          <button
            onClick={() => save(formData)}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sportgreen hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sportgreen disabled:opacity-50"
            disabled={loading}
          >
            {actionName || "Save"}
          </button>
        )}
      </FieldGroup>
    </Fieldset>
  );
}

export const FormWrapper = ({ children }: { children: any }) => {
  return <>{children}</>;
};
