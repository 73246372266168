import { useMutation, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import InventoryHeader from "../../../components/molecules/InventoryHeader";
import Loading from "../../../components/molecules/Loading";
import BikeNotFound from "../../../components/organisms/BikeNotFound";

import ShippingForm from "../../../components/molecules/ShippingForm";

import {
  FormButton,
  SubmittedInfo,
  queryShippingStatus,
  queryStatus,
} from "../../../components/organisms/Form";

import {
  BICYCLEQUERY,
  HANDED_IN,
  REQUESTSIGNATURE,
  UPDATE,
} from "../../../components/organisms/queries";

export default function PriceQueryDetails() {
  const params = useParams();
  const { loading, data, refetch } = useQuery(BICYCLEQUERY, {
    variables: { id: params.id },
  });
  const { user } = useAuth0();
  const [requestSignature] = useMutation(REQUESTSIGNATURE);
  const [handIn] = useMutation(HANDED_IN);
  const [update] = useMutation(UPDATE);

  const [saveLoading, setLoading] = useState(false);
  const onError = (err) => console.error(err);
  const bicyclePriceQuery = data?.bicyclePriceQuery;

  const requestWrapper = async (fn) => {
    try {
      setLoading(true);
      await fn();
    } catch (err) {
      onError(err);
    } finally {
      setLoading(false);
    }
  };

  const reopenPriceQuery = async (ev) => {
    ev.preventDefault();
    await requestWrapper(() =>
      update({
        variables: {
          id: bicyclePriceQuery.id,
          patch: {
            status: "SUBMITTED",
          },
        },
      })
    );
  };

  const onRequestSignature = async (ev) => {
    ev.preventDefault();

    await requestWrapper(() =>
      requestSignature({
        variables: {
          bicyclePriceQueryId: bicyclePriceQuery.id,
        },
      })
    );
  };

  const onHandIn = async (ev) => {
    ev.preventDefault();

    await requestWrapper(() =>
      handIn({
        variables: {
          bicyclePriceQueryId: bicyclePriceQuery.id,
        },
      })
    );
  };

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <BikeNotFound id={params.id} />;
  }

  // const showShippingForm = () => {
  //   console.log("test", bicyclePriceQuery.status, bicyclePriceQuery.type);
  //   if bicyclePriceQuery.status === ''

  //   return false
  // }

  const status = queryStatus(data?.bicyclePriceQuery);
  const showRequestSignature =
    bicyclePriceQuery?.type == "TRADEIN"
      ? status.accepted || status.signatureRequested
      : bicyclePriceQuery?.intakeStatus == "DONE";

  const bicycleIntakeForm = bicyclePriceQuery?.bicycleIntakeForms?.nodes[0];
  const shippingStatus = queryShippingStatus(bicyclePriceQuery);

  return (
    <>
      <InventoryHeader
        name={bicyclePriceQuery?.bicycle?.name}
        bicyclePriceQuery={bicyclePriceQuery}
        bicycleIntakeForm={bicycleIntakeForm}
        inventory={bicycleIntakeForm?.inventories?.nodes[0]}
        order={bicycleIntakeForm?.inventories?.nodes[0]?.order}
      />
      <div className="space-y-8  mt-4 mx-2 flex flex-col">
        <SubmittedInfo user={user} bicyclePriceQuery={bicyclePriceQuery} />
      </div>
      <div className="flex justify-start space-x-2 px-3 py-6">
        {bicyclePriceQuery?.status === "REJECTED" && (
          <FormButton
            type="reopen"
            disabled={loading}
            onClick={reopenPriceQuery}
          />
        )}
        {showRequestSignature && (
          <FormButton
            type="requestSignature"
            disabled={loading}
            onClick={onRequestSignature}
          />
        )}
        {bicyclePriceQuery?.type == "TRADEIN" && status.signed && (
          <FormButton type="handIn" disabled={loading} onClick={onHandIn} />
        )}
      </div>
      <div className="flex justify-start space-x-2 px-3 py-6">
        {(shippingStatus.handedIn || shippingStatus.accepted) && (
          <ShippingForm
            estimatedArrival={bicyclePriceQuery?.estimatedArrival}
            shippingReference={bicyclePriceQuery?.shippingReference}
            shippingCost={bicyclePriceQuery?.ourShippingCost}
            intakeStatus={bicyclePriceQuery?.intakeStatus}
            refetch={refetch}
          />
        )}
      </div>
    </>
  );
}
