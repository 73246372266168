import Form from "../../../components/organisms/CMSForm";
import Drawer from "../../../components/molecules/Drawer";
import type { ItemSchema } from "@app/types";
import React, { useState } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";

const dictionary = {};

export default function AddForm({
  refetch,
  schema,
  defaultValues = { data: {}, extraData: {} },
}: {
  refetch: () => void;
  schema: ItemSchema;
  defaultValues?: any;
}) {
  const [createItem] = schema.useCreateItemMutation();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const initialValues = schema.addInitialValues;
  if (defaultValues) {
    defaultValues?.data &&
      Object.keys(defaultValues?.data).forEach((key) => {
        initialValues[key] = defaultValues["data"][key];
      });
  }

  console.log("initialValues", initialValues);
  console.log("defaultValues", defaultValues);

  const submit = async (data: any) => {
    setLoading(true);
    const input = schema.createAddInputFromValues
      ? schema.createAddInputFromValues(data)
      : { input: schema.createInputFromValues(data) };
    console.log("data", data, input);
    await createItem({
      variables: input,
    });
    setLoading(false);
    setOpen(false);
    refetch();
  };
  return (
    <>
      <button
        onClick={() => setOpen(true)}
        className="border border-gray-200 rounded-md w-7 h-7 flex justify-center items-center hover:bg-gray-100 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sportgreen"
      >
        <PlusIcon className="h-5 w-5 text-gray-600" />
      </button>
      <Drawer title={`Add ${schema.name}`} open={open} setOpen={setOpen}>
        <Form
          title={`Add ${schema.name}`}
          form={schema.addForm}
          data={initialValues}
          dictionary={dictionary}
          extraData={defaultValues?.extraData || {}}
          loading={loading}
          save={submit}
          itemId={null}
        />
      </Drawer>
    </>
  );
}
