import {
  OrderStatus,
  OrdersOrderBy,
  PaymentMethod,
  useCreateOrderMutation,
  useOrdersQuery,
  usePimOrderQuery,
  useReferenceInventoriesLazyQuery,
  useUpdateOrderMutation,
} from "@app/graphql/pim";

import { getOptionsFromEnum } from "../utils/schema";

import {
  FieldType,
  Form,
  ItemFilter,
  ItemSchema,
  ListColumn,
} from "@app/types";
import { z } from "zod";
import { dateTimeFormat } from "../utils/dates";
import Header from "./componets/order/Header";
import { OrderSummary } from "./componets/order/OrderSummary";
import ShippingForm from "./componets/order/ShippingForm";
import PaymentForm from "./componets/order/PaymentForm";
export const addInitialValues = {
  inventoryId: null,
  paymentMethod: "invoice",
};

//invoice paid_amount=$2,, status, fiken_invoice_id = $1,  fiken_sale_id = $2, fiken_inventory_usage_id = $4

export const addForm: Form = [
  {
    id: "inventoryId",
    name: "Inventory",
    type: FieldType.reference,
    required: true,
    zFieldType: z.string(),
    fieldProps: {
      useGetSearchResultsLazyQuery: useReferenceInventoriesLazyQuery,
      parseSearchResult: (data: any) => data?.inventories?.nodes || [],
      parseDefaultName: (data: any) => data?.inventory?.name || "",
    },
  },
  {
    id: "paymentMethod",
    name: "Payment Method",
    type: FieldType.select,
    values: getOptionsFromEnum(PaymentMethod),
    zFieldType: z.nativeEnum(PaymentMethod),
    required: true,
  },
];

export const editForm: Form = [
  {
    id: "header",
    name: "Header",
    type: FieldType.component,
    colspan: 6,
    required: false,
    fieldProps: {
      component: Header,
    },
  },
  {
    id: "id",
    name: "Order ID",
    type: FieldType.label,
    colspan: 2,
    required: false,
  },
  {
    id: "created",
    name: "Opprettet",
    type: FieldType.label,
    colspan: 2,
    required: false,
    parseFormData: (data) => dateTimeFormat(data),
  },

  {
    id: "status",
    name: "Status",
    type: FieldType.label,
    colspan: 2,
    required: false,
  },
  {
    id: "expires",
    name: "Expires",
    type: FieldType.label,
    colspan: 2,
    required: false,
    parseFormData: (data) => dateTimeFormat(data),
  },
  {
    id: "lastUpdated",
    name: "Oppdatert",
    type: FieldType.label,
    colspan: 2,
    required: false,
    parseFormData: (data) => dateTimeFormat(data),
  },

  {
    id: "paymentMethod",
    name: "Betalingsmetode",
    type: FieldType.label,
    colspan: 2,
    required: false,
  },
  {
    id: "transactionStatus",
    name: "Betalingsstatus",
    type: FieldType.label,
    colspan: 2,
    required: false,
  },
  {
    id: "shippingMethod",
    name: "Fraktmetode",
    type: FieldType.label,
    colspan: 2,
    required: false,
  },

  {
    id: "summary",
    name: "Order summary",
    type: FieldType.component,
    colspan: 6,
    required: false,
    fieldProps: {
      component: OrderSummary,
    },
  },
  {
    id: "shippingFirstName",
    name: "Fornavn",
    type: FieldType.text,
    colspan: 3,
    required: false,
  },
  {
    id: "shippingLastName",
    name: "Etternavn",
    type: FieldType.text,
    colspan: 3,
    required: false,
  },
  {
    id: "shippingAddressLine",
    name: "Adresse",
    type: FieldType.text,
    colspan: 6,
    required: false,
  },
  {
    id: "shippingPostalCode",
    name: "Postnummer",
    type: FieldType.text,
    colspan: 1,
    required: false,
  },
  {
    id: "shippingPostalPlace",
    name: "Poststed",
    type: FieldType.text,
    colspan: 3,
    required: false,
  },
  {
    id: "shippingCountry",
    name: "Land",
    type: FieldType.text,
    colspan: 2,
    required: false,
  },
  {
    id: "email",
    name: "Email",
    type: FieldType.text,
    colspan: 2,
    required: false,
  },
  {
    id: "phoneNumber",
    name: "Telefon",
    type: FieldType.text,
    colspan: 2,
    required: false,
  },
  {
    id: "payment",
    name: "Payment",
    type: FieldType.component,
    colspan: 6,
    required: false,
    fieldProps: {
      component: PaymentForm,
    },
  },
  {
    id: "actions",
    name: "Shipping",
    type: FieldType.component,
    colspan: 6,
    required: false,
    fieldProps: {
      component: ShippingForm,
    },
  },
];

export const editInitialValues = {
  email: "",
  phoneNumber: "",

  shippingFirstName: "",
  shippingLastName: "",
  shippingAddressLine: "",
  shippingPostalCode: "",
  shippingPostalPlace: "",
  shippingCountry: "",
};

export const listColumns: ListColumn[] = [
  {
    id: "created",
    name: "Opprettet",
    parseFormData: (data) => dateTimeFormat(data),
    sortBy: "CREATED",
  },

  {
    id: "summary",
    name: "Summary",
  },
  {
    id: "status",
    name: "Status",
    sortBy: "STATUS",
  },
  {
    id: "expires",
    name: "Expires",
    parseFormData: (data) => dateTimeFormat(data),
  },

  {
    id: "paymentMethod",
    name: "Betalingsmetode",
  },
  {
    id: "transactionStatus",
    name: "Betalingsstatus",
  },

  {
    id: "total",
    name: "Total",
    parseFormData: (data) => `${data / 100},-` || "",
  },
  {
    id: "lastUpdated",
    name: "Oppdatert",
    sortBy: "LAST_UPDATED",
    parseFormData: (data) => dateTimeFormat(data),
  },
];

const filters: ItemFilter[] = [
  {
    name: "status",
    type: "enum",
    enum: OrderStatus,
  },
];

const product: ItemSchema = {
  id: "order",
  name: "Order",
  plural: "Orders",
  listColumns,
  addForm,
  editForm,
  addInitialValues,
  editInitialValues,
  filters,
  defaultOrderBy: OrdersOrderBy.CreatedDesc,
  useItemsQuery: useOrdersQuery,
  useItemQuery: usePimOrderQuery,
  useCreateItemMutation: useCreateOrderMutation,
  useUpdateItemMutation: useUpdateOrderMutation,
  parseItem: (data) => data?.order || {},
  parseItems: (data) => ({
    totalCount: data?.orders?.totalCount,
    items: data?.orders?.nodes,
  }),
  createInputFromValues: (values) => ({
    order: values,
  }),
  createAddInputFromValues: (values) => values,
};

export default product;
