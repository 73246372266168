import { ChevronDownIcon, Cog8ToothIcon } from "@heroicons/react/16/solid";
import { Avatar } from "../atom/avatar";
import PartnerLoading from "./PartnerLoading.svg";
import { useSportiendaOrg } from "../../hooks/org";
import { classNames } from "../../utils/dom";

import {
  Dropdown,
  DropdownButton,
  DropdownHeader,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "../atom/dropdown";

function DefaultLabel({
  children,
  className,
}: {
  children: React.ReactNode;
  className: string;
}) {
  return <div className={className}>{children}</div>;
}

export default function OrgDropDown({
  as,
  small = false,
  Label = DefaultLabel,
}: {
  as: any;
  small?: boolean;
  Label?: React.ComponentType<{ children: React.ReactNode; className: string }>;
}) {
  const { org, loading } = useSportiendaOrg();

  const logo = org?.logo || PartnerLoading;
  return (
    <Dropdown>
      <DropdownButton as={as} className="lg:mb-2.5">
        <Avatar src={logo} square />
        <DefaultLabel className={classNames(small ? "max-lg:hidden" : "")}>
          {org?.name}
        </DefaultLabel>
        <ChevronDownIcon />
      </DropdownButton>
      <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
        {small && <DropdownHeader>{org?.name}</DropdownHeader>}
        {/* <DropdownItem href={`/partners/${org?.id}/settings`}>
          <Cog8ToothIcon />
          <DropdownLabel>Partnerinstillinger</DropdownLabel>
        </DropdownItem> */}
      </DropdownMenu>
    </Dropdown>
  );
}
