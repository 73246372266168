import {
  useOrgQuery,
  useOrgsQuery,
  useUpdateOrgMutation,
} from "@app/graphql/pim";
import { FieldType, Form, ItemSchema, ListColumn } from "@app/types";
import { z } from "zod";
import org_permission from "./org_permission";

export const addInitialValues = {
  name: null,
  fikenId: null,
  tradein: false,
};

export const addForm: Form = [
  {
    id: "name",
    name: "Partnernavn",
    colspan: 4,
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "fikenId",
    name: "Fiken Levrandørnummersen",
    colspan: 4,
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "tradein",
    name: "Tilbyr innbytte",
    type: FieldType.boolean,
    colspan: 4,
    required: false,
  },
];

// notification_email: string | null;

//   address: string | null;

//   postal_code: string | null;

//   postal_place: string | null;

//   phone: string | null;

//   website: string | null;

//   logo_url: string | null;

//   email: string | null;

//   description: string | null;

export const editForm: Form = [
  ...addForm,

  {
    id: "finnId",
    name: "Finn id for sync",
    colspan: 4,
    type: FieldType.text,
    required: false,
    zFieldType: z.string(),
  },

  {
    id: "description",
    name: "Description",
    colspan: 4,
    type: FieldType.text,
    required: false,
    zFieldType: z.string(),
  },
  {
    id: "notificationEmail",
    name: "Notification email",
    colspan: 4,
    type: FieldType.text,
    required: false,
    zFieldType: z.string(),
  },

  {
    id: "address",
    name: "Address",
    colspan: 4,
    type: FieldType.text,
    required: false,
    zFieldType: z.string(),
  },

  {
    id: "postalCode",
    name: "Postal code",
    colspan: 4,
    type: FieldType.text,
    required: false,
    zFieldType: z.string(),
  },

  {
    id: "postalPlace",
    name: "Postal place",
    colspan: 4,
    type: FieldType.text,
    required: false,
    zFieldType: z.string(),
  },

  {
    id: "phone",
    name: "Phone",
    colspan: 4,
    type: FieldType.text,
    required: false,
    zFieldType: z.string(),
  },

  {
    id: "website",
    name: "Website",
    colspan: 4,
    type: FieldType.text,
    required: false,
    zFieldType: z.string(),
  },

  {
    id: "logoUrl",
    name: "Logo URL",
    colspan: 4,
    type: FieldType.text,
    required: false,
    zFieldType: z.string(),
  },

  {
    id: "email",
    name: "Email",
    colspan: 4,
    type: FieldType.text,
    required: false,
    zFieldType: z.string(),
  },
];

export const editInitialValues = {
  ...addInitialValues,
  finnId: null,
  description: null,
  notificationEmail: null,
  address: null,
  postalCode: null,
  postalPlace: null,
  phone: null,
  website: null,
  logoUrl: null,
  email: null,
};

export const listColumns: ListColumn[] = [
  {
    id: "id",
    name: "Auth0 id",
  },

  {
    id: "name",
    name: "Partnernavn",
  },

  {
    id: "fikenId",
    name: "Fiken Levrandørnummer",
  },
];

const org: ItemSchema = {
  id: "org",
  name: "Partner",
  plural: "Partnere",
  listColumns,
  addForm: null,
  editForm,
  addInitialValues,
  editInitialValues,
  useItemsQuery: useOrgsQuery,
  useItemQuery: useOrgQuery,
  useCreateItemMutation: null,
  useUpdateItemMutation: useUpdateOrgMutation,
  parseItem: (data) => data?.org || {},
  parseItems: (data) => ({
    totalCount: data?.orgs?.totalCount,
    items: data?.orgs?.nodes,
  }),
  createInputFromValues: (values) => ({
    org: values,
  }),
  children: [org_permission],
  parentId: "orgId",
};

export default org;
