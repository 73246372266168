import { useEffect, useState } from "react";

function DevInfo() {
  const [info, setInfo] = useState("loading...");
  useEffect(() => {
    const run = async () => {
      if (!process.env.REACT_APP_REST_API_BASE_URL) {
        setInfo("REST_API_BASE_URL not set");
        return;
      }
      const data = await fetch(
        `${process.env.REACT_APP_REST_API_BASE_URL}/devinfo`
      );
      const text = await data.text();
      setInfo(text);
    };
    run();
  }, []);

  return (
    <div className="px-4 py-2 bg-black text-yellow-300">
      <pre>{info}</pre>
    </div>
  );
}

export default function DevInfoWrapper() {
  if (
    process.env.NODE_ENV === "development" &&
    process.env.REACT_APP_REST_API_BASE_URL
  ) {
    return <DevInfo />;
  }
  return null;
}
