import clsx from "clsx";

type CaptionProps = React.ComponentPropsWithoutRef<"caption">;

export function Caption({ className, ...props }: CaptionProps) {
  return (
    <caption
      {...props}
      className={clsx(
        className,
        "text-base/7 font-semibold text-zinc-950 sm:text-sm/6 dark:text-white"
      )}
    />
  );
}
