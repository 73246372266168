import {
  CloudArrowUpIcon,
  DocumentIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { classNames } from "../../utils/dom";

/* eslint-disable react/prop-types */

function shortenBytes(n: any) {
  const k = n > 0 ? Math.floor(Math.log2(n) / 10) : 0;
  const rank = (k > 0 ? "KMGT"[k - 1] : "") + "b";
  const count = Math.floor(n / Math.pow(1024, k));
  return count + rank;
}

type Image = {
  id: string;
  file: File | null;
  preview: string;
};

const getImageObjects = (imageIds: string[] | undefined) => {
  if (!imageIds) {
    return [];
  }
  return imageIds
    .filter((i) => i)
    .map((i) => ({
      id: i,
      file: null,
      preview: `https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/${i}/public`,
    }));
};

export function ImageUpload({
  onChange,
  onRemoved,
  imageList,
  imageIds,
  label,
  required = false,
  multiple = true,
}: {
  onChange?: (images: [any]) => Promise<void> | void;
  onRemoved?: (image: any) => Promise<void> | void;
  imageList?: Image[];
  imageIds?: string[];
  label?: string | null | React.ReactNode;
  required?: boolean;
  multiple?: boolean;
}) {
  const [images, setImages] = useState(
    imageList ?? getImageObjects(imageIds) ?? []
  );

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const newImages = acceptedFiles.map((iter: any) => ({
        file: iter,
        preview: URL.createObjectURL(iter),
      }));

      const list = [...newImages, ...images];
      setImages(list);

      if (onChange) {
        onChange(newImages.filter((image: Image) => !!image.file));
      }
    },
    [images]
  );

  const onRemove = (index: number) => {
    const removed = images[index];
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
    onRemoved?.(removed);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const showRemove = (image: Image) => !!image.file || !!onRemoved;

  const inputProps = getInputProps() as any;

  useEffect(() => {
    // Clears URL objects to avoid memory leaks
    return () => {
      images
        .filter((image) => !!image.file)
        .forEach((image) => URL.revokeObjectURL(image.preview));
    };
  }, [images]);

  return (
    <div>
      {label && (
        <div className="block text-sm font-medium text-gray-700">
          {label}
          {required && <span className="text-red-500">*</span>}
        </div>
      )}

      <div className="flex items-center justify-left p-4 rounded border-2 border-dashed border-gray-200 bg-white">
        <div className="overflow-hidden">
          {(multiple || images.length === 0) && (
            <div {...getRootProps({ className: "p-8 text-center space-y-2" })}>
              <div className="text-sporticon-fg">
                <CloudArrowUpIcon className="inline h-8 w-8" />
              </div>
              <div>
                <input {...inputProps} />
                {isDragActive ? (
                  <p>Slipp filene dine her ...</p>
                ) : (
                  <p>
                    Last opp filer ved å dra og slipp dem her eller ved å klikke
                    på knappen nedenfor.
                  </p>
                )}
              </div>
              <div>
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-sportgreen py-2 px-4 text-sm font-medium  text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-sportgreen focus:ring-offset-2"
                >
                  Last opp bilder
                </button>
              </div>
            </div>
          )}
          {images.length == 0 && required && (
            <input
              className="opacity-0"
              type="text"
              name="images"
              value=""
              required={required}
            />
          )}
          {images.length > 0 && (
            <div className="mb-4 flex justify-center space-x-2 overflow-auto">
              {images.map((image, i) => {
                const isNotImage =
                  image?.preview?.includes("cloudflarestorage") ||
                  (image?.file?.type && !image?.file?.type?.includes("image"));
                return (
                  <div
                    key={image.preview}
                    className={classNames(
                      !isNotImage ? "w-72" : " w-28 rounded-md",
                      "mt-4 relative flex flex-shrink-0 items-center overflow-hidden bg-sportgreyweb text-white"
                    )}
                  >
                    {isNotImage ? (
                      <div className="w-full">
                        {/* center icon */}
                        <DocumentIcon className="h-28 w-20" />
                      </div>
                    ) : (
                      <img
                        alt={image.preview}
                        src={image.preview}
                        className="w-full"
                      />
                    )}

                    {image.file && (
                      <div className="absolute bottom-0 right-0 left-0 truncate bg-black/50 p-2 text-center text-sm">
                        <div>{image.file.name}</div>
                        <div>{shortenBytes(image.file.size)}</div>
                      </div>
                    )}

                    {showRemove(image) && (
                      <div
                        className="absolute top-4 right-4 flex cursor-pointer items-center justify-center"
                        onClick={() => onRemove(i)}
                      >
                        <span>
                          <XCircleIcon className="h-7 w-7  rounded-full bg-gray-400 hover:ring-2 hover:ring-gray-500" />
                        </span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
