import { InventoryStatus } from "@app/graphql/pim";
import { Color } from "../components/atom/badge";
// Auction = 'AUCTION',
// AuctionDone = 'AUCTION_DONE',
// Draft = 'DRAFT',
// ForSale = 'FOR_SALE',
// ForSaleHidden = 'FOR_SALE_HIDDEN',
// Pending = 'PENDING',
// Retracted = 'RETRACTED',
// Sold = 'SOLD'
// red
// orange
// amber
// yellow
// lime
// green
// emerald
// teal
// cyan
// sky
// blue
// indigo
// violet
// purple
// fuchsia
// pink
// rose
// zinc
export const inventoryStatusColors: Record<InventoryStatus, Color> = {
  [InventoryStatus.ForSale]: "green",
  [InventoryStatus.Sold]: "orange",
  [InventoryStatus.Auction]: "red",
  [InventoryStatus.AuctionDone]: "yellow",
  [InventoryStatus.Draft]: "zinc",
  [InventoryStatus.ForSaleHidden]: "lime",
  [InventoryStatus.Pending]: "amber",
  [InventoryStatus.Retracted]: "fuchsia",
};
