import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { dateTimeFormat } from "../../../utils/dates";
import { classNames } from "../../../utils/dom";
import { useSelectAll } from "../../../utils/selection";
// import FilterText from '../../../components/molecules/FilterText'
import FilterSingle from "../../../components/molecules/FilterSingle";
import Pagination from "../../../components/molecules/Pagination";
import SelectedRows from "../../../components/molecules/SelectedRows";
import SortableColumn from "../../../components/molecules/SortableColumn";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/atom/table";
import { Checkbox } from "../../../components/atom/checkbox";
import { Subheading } from "../../../components/atom/heading";
import useEnum from "../../../utils/enum";
import { useBicyclePriceQueries } from "./hooks/useBicyclePriceQueries";
import BulkActions from "./partials/BulkActions";

const PAGESIZE = 20;

export default function PriceQueryList({ type }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const STATUS = useEnum("PriceQueryStatus");
  const TYPE = useEnum("PriceQueryType");
  const { data, pricequeries, totalCount } = useBicyclePriceQueries(
    PAGESIZE,
    type
  );
  const selectAllRef = useRef();
  const { selected, setSelected, selection } = useSelectAll(
    selectAllRef,
    pricequeries
  );
  const allOrPageSelected =
    selected.length > 0 &&
    (selected.length >= PAGESIZE || selected.length === totalCount);
  return (
    <>
      <div className="mt-8 flex items-end justify-between">
        <Subheading>Pricequeries</Subheading>
        <div className="flex gap-x-2">
          <FilterSingle name="status" values={STATUS} />
          {!type && <FilterSingle name="type" values={TYPE} />}

          <BulkActions selected={selected} selection={selection} data={data} />
        </div>
      </div>
      <SelectedRows
        selected={selected}
        setSelected={setSelected}
        selection={selection}
        totalCount={totalCount}
        pageSize={PAGESIZE}
      />
      <Table striped={true} dense={true}>
        <TableHead>
          <TableRow>
            <TableHeader>
              <Checkbox
                name="select-all"
                type="checkbox"
                className="mr-4 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                value="current-page"
                checked={allOrPageSelected}
                onChange={(e) =>
                  setSelected({
                    target: {
                      value: "current-page",
                      checked: !allOrPageSelected,
                      tagName: "input",
                    },
                  })
                }
                ref={selectAllRef}
              />
            </TableHeader>
            <TableHeader>
              <SortableColumn field="OWNER_EMAIL">OWNER_EMAIL</SortableColumn>
            </TableHeader>
            <TableHeader className="px-4 py-4 font-medium">
              <SortableColumn field="OWNER_LAST_NAME" />
            </TableHeader>

            <TableHeader className="px-4 py-4 font-medium">Bicycle</TableHeader>

            <TableHeader className="px-4 py-4 font-medium">
              <SortableColumn field="TYPE" />
            </TableHeader>

            <TableHeader className="px-4 py-4 font-medium">
              <SortableColumn field="STATUS" />
            </TableHeader>

            <TableHeader className="px-4 py-4 font-medium">
              <SortableColumn field="LAST_UPDATED" />
            </TableHeader>

            <TableHeader className="px-4 py-4 font-medium">
              <SortableColumn field="CREATED" />
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {pricequeries.map((pricequery) => (
            <TableRow
              key={pricequery.id}
              onClick={() => navigate(pricequery.id)}
              className={classNames({
                "bg-indigo-50": selected.includes(pricequery.id),
              })}
            >
              <TableCell>
                <Checkbox
                  name="pricequery"
                  type="checkbox"
                  onClick={(event) => event.stopPropagation()}
                  value={pricequery.id}
                  checked={selected.includes(pricequery.id)}
                  onChange={(e) =>
                    setSelected({
                      target: {
                        value: pricequery.id,
                        checked: !selected.includes(pricequery.id),
                        tagName: "input",
                      },
                    })
                  }
                />
              </TableCell>
              <TableCell>{pricequery.ownerEmail}</TableCell>

              <TableCell>
                {pricequery.ownerFirstName} {pricequery.ownerLastName}
              </TableCell>

              <TableCell>{pricequery?.bicycle?.name}</TableCell>
              <TableCell> {pricequery?.type}</TableCell>

              <TableCell>{pricequery?.status}</TableCell>

              <TableCell>
                {pricequery.lastUpdated &&
                  dateTimeFormat(pricequery.lastUpdated)}
              </TableCell>

              <TableCell>
                {pricequery.created && dateTimeFormat(pricequery.created)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination totalCount={totalCount} pageSize={PAGESIZE} />
    </>
  );
}
