"use client";

import React, { useEffect } from "react";
import { Transition } from "@headlessui/react";
// Heroicons (pick icons that suit your project)
import {
  CheckCircleIcon,
  ExclamationTriangleIcon, // For warnings/errors
  InformationCircleIcon, // For info
  XMarkIcon, // The 'close' (X) icon
} from "@heroicons/react/24/outline";

/** Notification type definition */
export type NotificationType = "success" | "error" | "info" | "warn";

/** Props for Notification component */
export interface NotificationProps {
  /** Controls whether to show the notification */
  show: boolean;
  /** Setter to change notification visibility */
  setShow: (visible: boolean) => void;
  /** The type of notification (affects icon, styling) */
  type?: NotificationType;
  /** Main heading/title */
  title?: string;
  /** Additional message text */
  message?: string;
  /** Auto-close the notification after a given duration (ms)? */
  autoClose?: boolean;
  /** Duration in ms before auto-close (default 3000ms) */
  autoCloseDuration?: number;
}

/**
 * A reusable toast-like Notification, positioned at bottom-left
 * on mobile and bottom-right on larger screens.
 */
export default function Notification({
  show,
  setShow,
  type = "success",
  title = "",
  message = "",
  autoClose = false,
  autoCloseDuration = 3000,
}: NotificationProps) {
  // Close automatically after specified duration
  useEffect(() => {
    if (autoClose && show) {
      const timer = setTimeout(() => setShow(false), autoCloseDuration);
      return () => clearTimeout(timer);
    }
  }, [autoClose, autoCloseDuration, show, setShow]);

  // Map each type to a suitable icon (and optional color)
  const iconMap: Record<NotificationType, JSX.Element> = {
    success: (
      <CheckCircleIcon aria-hidden="true" className="size-6 text-green-400" />
    ),
    error: (
      <ExclamationTriangleIcon
        aria-hidden="true"
        className="size-6 text-red-400"
      />
    ),
    info: (
      <InformationCircleIcon
        aria-hidden="true"
        className="size-6 text-blue-400"
      />
    ),
    warn: (
      <ExclamationTriangleIcon
        aria-hidden="true"
        className="size-6 text-yellow-400"
      />
    ),
  };

  return (
    // Live region for a11y
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition show={show}>
          {/* Panel container */}
          <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black/5 transition">
            <div className="p-4">
              <div className="flex items-start">
                {/* Icon */}
                <div className="shrink-0">{iconMap[type]}</div>
                {/* Text */}
                <div className="ml-3 flex-1">
                  {title && (
                    <p className="text-sm font-medium text-gray-900">{title}</p>
                  )}
                  {message && (
                    <p className="mt-1 text-sm text-gray-500">{message}</p>
                  )}
                </div>
                {/* Close button */}
                <div className="ml-4 flex shrink-0">
                  <button
                    type="button"
                    onClick={() => setShow(false)}
                    className="inline-flex rounded-md bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">Close notification</span>
                    <XMarkIcon aria-hidden="true" className="size-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}
