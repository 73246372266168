import { gql, useMutation } from "@apollo/client";
import { ArrowUpCircleIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { useParams } from "react-router-dom";
import LoadingInline from "../molecules/LoadingInline";
import { Field } from "../organisms/Form";

const SAVE_INTAKE_SHIPPING = gql`
  mutation SaveIntakeShipping(
    $bicycleQueryId: UUID!
    $reference: String!
    $estimated: Datetime!
    $shippingCost: Int!
  ) {
    saveIntakeShipping(
      input: {
        bicycleQueryId: $bicycleQueryId
        reference: $reference
        estimated: $estimated
        shippingCost: $shippingCost
      }
    ) {
      bicycleIntakeForm {
        id
        status
      }
    }
  }
`;

export default function ShippingForm({
  estimatedArrival,
  shippingReference,
  intakeStatus,
  shippingCost,
  refetch,
}) {
  const params = useParams();
  const [saveIntakeShipping, { loading }] = useMutation(SAVE_INTAKE_SHIPPING);

  const [reference, setReference] = useState(shippingReference);
  const [cost, setCost] = useState(shippingCost);
  const [arrival, setArrival] = useState(estimatedArrival);
  const onSubmit = async (e) => {
    e.preventDefault();

    const refetchQueries = ["BicyclePriceQueryQuery"];
    const variables = {
      bicycleQueryId: params.id,
      reference: reference,
      estimated: arrival,
      shippingCost: parseInt(cost),
    };

    await saveIntakeShipping({ variables, refetchQueries });
    refetch();
    // console.log("Finished:", res?.data?.bicycleIntakeForm?.id);
  };

  return (
    <div className="mb-6 overflow-hidden bg-gray-300 shadow sm:rounded-lg w-full">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Shipping details
        </h3>
      </div>
      <div className="px-4 py-5 border-t border-gray-200 overflow-hidden">
        <form onSubmit={onSubmit}>
          <div className="flex mb-8">
            <Field
              fieldId={"shippingReference"}
              fieldName={"Shipping reference"}
              required={true}
              className={"grow"}
              value={reference}
              onChange={setReference}
              type={"text"}
            />
          </div>
          <div className="flex mb-8">
            <Field
              fieldId={"cost"}
              fieldName={"Shipping cost"}
              required={true}
              className={"grow"}
              value={cost}
              onChange={setCost}
              type={"number"}
            />
          </div>
          <div className="flex mb-8">
            <Field
              fieldId={"estimatedArrival"}
              fieldName={"Estimated arrival"}
              required={true}
              className={"grow"}
              value={arrival}
              onChange={setArrival}
              type={"datetime-local"}
            />
          </div>

          {intakeStatus && (
            <div className="mb-8">
              Intake status:{" "}
              <span className="font-bold lowercase"> {intakeStatus}</span>
            </div>
          )}
          {loading ? (
            <LoadingInline />
          ) : (
            <>
              {!intakeStatus && (
                <button
                  type="submit"
                  disabled={loading}
                  className="inline-flex justify-center space-x-2 rounded-md border border-transparent bg-sportgreen py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-sportgreen focus:ring-offset-2"
                >
                  <span>Start shipment</span>
                  <ArrowUpCircleIcon
                    className="mr-2 h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </button>
              )}
            </>
          )}
        </form>
      </div>
    </div>
  );
}
