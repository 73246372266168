import { useMachine } from "@xstate/react";
import { createMachine, getNextSnapshot } from "xstate";
import { useState, useEffect } from "react";
import {
  EllipsisVerticalIcon,
  ChevronDownIcon,
} from "@heroicons/react/16/solid";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../atom/dropdown";
import type { AnyMachineSnapshot } from "xstate";
import { inventoryStatusColors } from "../../utils/colors";
import {
  useUpdateInventoryMutation,
  InventoryStatus as InventoryStatusEnum,
} from "@app/graphql/pim";
function getNextEvents(snapshot: AnyMachineSnapshot) {
  //@ts-ignore
  return [...new Set([...snapshot._nodes.flatMap((sn) => sn.ownEvents)])];
}
export const inventoryMachine = createMachine({
  id: "inventoryStatus",
  initial: "draft",
  states: {
    draft: {
      on: {
        PUBLISH: "for_sale",
        REMOVE: "retracted",
        AUCTION_OFF: "auction",
        HIDE: "for_sale_hidden",
      },
    },
    for_sale: {
      on: {
        HIDE: "for_sale_hidden",
        RETRACT: "retracted",
        AUCTION_OFF: "auction",
      },
    },
    for_sale_hidden: {
      on: { UNHIDE: "for_sale", RETRACT: "retracted", AUCTION_OFF: "auction" },
    },
    retracted: {
      on: {
        PUBLISH: "for_sale",
        AUCTION_OFF: "auction",
        HIDE: "for_sale_hidden",
      },
    },
    sold: {
      type: "final",
    },
    auction: {
      type: "final",
    },
    auction_done: {
      type: "final",
    },
  },
});

export function InventoryStatus({
  inventoryId,
  initialStatus,
  callback,
  validateTransition,
  edited,
}: {
  inventoryId: string;
  initialStatus: string;
  callback: () => void;
  validateTransition: (status: InventoryStatusEnum) => boolean;
  edited?: Date | null;
}) {
  const [update] = useUpdateInventoryMutation();
  const resolvedState = inventoryMachine.resolveState({
    value: initialStatus.toLowerCase(),
  });

  const [state, send] = useMachine(inventoryMachine, {
    state: resolvedState,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const doUpdate = async () => {
      const status = state.value.toString().toUpperCase();
      const enumValues = Object.values(InventoryStatusEnum);
      if (!enumValues.includes(status as any)) {
        throw new Error(`Invalid inventory status: ${status}`);
      }

      await update({
        variables: {
          id: inventoryId,
          patch: {
            status: status as InventoryStatusEnum,
          },
        },
      });
      callback();
    };
    doUpdate();
  }, [state.value]);
  const color =
    inventoryStatusColors[
      (state.value.toString().toUpperCase() as InventoryStatusEnum) ||
        ("DRAFT" as InventoryStatusEnum)
    ];
  const availableEvents: any[] = getNextEvents(state);
  const eventLabels: any = {
    PUBLISH: "Legg ut for salg",
    RETRACT: "Trekk tilbake fra salg",
    HIDE: "Legg ut til salg på skjult link",
    UNHIDE: "Legg ut til åpent salg",
    AUCTION_OFF: "Legg ut på auksjon",
    REMOVE: "Arkiver",
  };

  const triggerTransition = async (selectedEvent: string) => {
    if (selectedEvent) {
      setIsLoading(true);
      try {
        const value = state;
        console.log("value", value);
        const nextState = getNextSnapshot(inventoryMachine, value, {
          type: selectedEvent,
        });

        const isValid = validateTransition(
          nextState.value.toString().toUpperCase() as InventoryStatusEnum
        );

        if (!isValid) {
          console.warn("Validation failed, state transition aborted.");
          return;
        }

        // Proceed with the transition if validation passes
        send({ type: selectedEvent });
      } catch (error) {
        console.error("Transition failed:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Dropdown>
      <DropdownButton plain aria-label="More options">
        <ChevronDownIcon />
      </DropdownButton>
      <DropdownMenu anchor="bottom end">
        {!!edited && (
          <DropdownItem className="italic">
            Du må lagre før du kan endre status
          </DropdownItem>
        )}
        {availableEvents.map((eventType: any) => (
          <DropdownItem
            key={eventType}
            className="disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isLoading || !!edited}
            onClick={() => triggerTransition(eventType)}
          >
            {eventLabels[eventType] || eventType}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
