export default function TrelloPage() {
  return (
    <div className="w-full h-full">
      <a
        href="https://trello.com/b/NxvD64ZU/inn-og-utleveringer"
        target="_blank"
        rel="noreferrer"
        className="block text-center p-1 text-sm underline"
      >
        åpne i trello
      </a>
      <iframe
        src="https://trello.com/b/NxvD64ZU.html"
        frameBorder="0"
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
}
