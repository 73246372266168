import {
  StatsDashboard,
  // StatsFavourite,
  StatsInventory,
  useStatsDashboardQuery,
} from "@app/graphql/pim";
import { Select } from "../atom/select";
import { Heading, Subheading } from "../atom/heading";
import { Caption } from "../atom/caption";
import { Stat } from "../molecules/Stat";
import { Divider } from "../atom/divider";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../atom/table";

// import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import AuctionStats from "../../components/molecules/AuctionStats";
import Loading from "../../components/molecules/Loading";
type DashboardStatsProps = {
  data: StatsDashboard;
};

// type FavouritesProps = {
//   favourites: StatsFavourite[];
// };

// function Favourites({ favourites }: FavouritesProps) {
//   return (
//     <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
//       <div className="px-4 py-5 sm:px-6">Mest favorittert siste 2 måneder</div>
//       <div className="px-4 py-5 sm:p-6">
//         <ul role="list" className="divide-y divide-gray-100">
//           {favourites.map((favourite) => (
//             <li
//               key={favourite.id}
//               className="flex gap-x-4 py-5 justify-between"
//             >
//               <img
//                 className="h-12 w-18 flex-none rounded bg-gray-50"
//                 src={`https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/${favourite.image}/public`}
//                 alt=""
//               />
//               <div className="min-w-0 grow">
//                 <p className="text-sm font-semibold leading-6 text-gray-900">
//                   <Link
//                     to={`pim/inventory/${favourite.id}`}
//                     className="hover: underline"
//                   >
//                     {" "}
//                     {favourite.name}
//                   </Link>
//                 </p>
//               </div>
//               <div className="text-sm w-6 grow-0 font-semibold leading-6 text-gray-900">
//                 {favourite.count}
//               </div>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// }
function signedString(value: number) {
  return `${value >= 0 ? "+" : ""}${value}`;
}
function DashboardStats({ data }: DashboardStatsProps): JSX.Element {
  const stats = [
    {
      name: "Salg",
      stat: data.salesLastWeeks,
      previousStat: data.salesPrevWeeks,
      change: `${signedString(
        ((data.salesLastWeeks - data.salesPrevWeeks) / data.salesPrevWeeks) *
          100
      )}%`,
    },
    {
      name: "Lagt ut til salgs",
      stat: data.onSalesLastWeeks,
      previousStat: data.onSalesPrevWeeks,
      change: `${signedString(
        ((data.onSalesLastWeeks - data.onSalesPrevWeeks) /
          data.onSalesPrevWeeks) *
          100
      )}%`,
    },
    {
      name: "Signerte kjøp",
      stat: data.singaturesLastWeeks,
      previousStat: data.singaturesPrevWeeks,
      change: `${signedString(
        ((data.singaturesLastWeeks - data.singaturesPrevWeeks) /
          data.singaturesPrevWeeks) *
          100
      )}%`,
    },
  ];

  return (
    <>
      <div className="mt-8 flex items-end justify-between">
        <Subheading>Oversikt</Subheading>
        <div>
          <Select name="period">
            <option value="last_two">Siste 14 dager</option>
          </Select>
        </div>
      </div>
      <div className="mt-4 grid gap-8 sm:grid-cols-3 xl:grid-cols-3">
        {stats.map((item) => (
          <Stat
            key={item.name}
            title={item.name}
            value={item.stat}
            change={item.change}
          />
        ))}
      </div>
    </>
  );
}

function InventoryStatsTable({
  groupedData,
  objectType,
}: {
  groupedData: any;
  objectType: string;
}): JSX.Element {
  if (!groupedData[objectType]) return <>No {objectType}</>;
  const subTypes = Object.keys(groupedData[objectType]);
  const statuses = Object.keys(groupedData[objectType][subTypes[0]] || {});

  return (
    <Table key={objectType}>
      <Caption>{objectType}</Caption>
      <TableHead>
        <TableRow>
          <TableHeader>Status \ SubType</TableHeader>
          {subTypes.map((subType) => (
            <TableHeader key={subType}>{subType}</TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {statuses.map((status) => (
          <TableRow key={status}>
            <TableCell>{status}</TableCell>
            {subTypes.map((subType) => (
              <TableCell key={subType}>
                {groupedData[objectType][subType][status] ? (
                  <>
                    <div>
                      Total: {groupedData[objectType][subType][status].total}
                    </div>
                    {Object.entries(
                      groupedData[objectType][subType][status].types
                    ).map(([type, count]) => (
                      <div key={type} className="text-xs">
                        {type}: <>{count || 0}</>
                      </div>
                    ))}
                  </>
                ) : (
                  <div>0</div>
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function InventoryStats({
  inventorystats,
}: {
  inventorystats: StatsInventory[];
}): JSX.Element {
  const groupedData = inventorystats.reduce((acc: any, item) => {
    const { objectType, subType, status, type, count } = item;
    if (!objectType || !subType || !status || !type || !count) return acc;
    const countInt = parseInt(count, 10);

    if (!acc[objectType]) acc[objectType] = {};
    if (!acc[objectType][subType]) acc[objectType][subType] = {};
    if (!acc[objectType][subType][status])
      acc[objectType][subType][status] = { total: 0, types: {} };
    if (!acc[objectType][subType][status].types[type])
      acc[objectType][subType][status].types[type] = 0;

    acc[objectType][subType][status].total += countInt;
    acc[objectType][subType][status].types[type] += countInt;

    return acc;
  }, {});
  return (
    <>
      <InventoryStatsTable groupedData={groupedData} objectType="BICYCLE" />
      <InventoryStatsTable groupedData={groupedData} objectType="SKI" />
    </>
  );
}

export default function InternalDashboard() {
  const { data, error, loading } = useStatsDashboardQuery();
  const { user } = useAuth0();
  if (user?.org_id !== process.env.REACT_APP_SPORTIENDA_ORG) {
    return null;
  }
  if (loading) return <Loading />;
  if (error) return <div>{error.message}</div>;
  const [keystats] = data?.statsDashboards?.nodes || [];
  const inventorystats = data?.statsInventories?.nodes || [];
  // const favourites = data?.statsFavourites?.nodes;

  return (
    <>
      <Heading>Sportienda Intern</Heading>
      {keystats && (
        <>
          <DashboardStats data={keystats} />
          <Divider />
        </>
      )}
      <AuctionStats title="Avsluttede Auksjoner" open={false} />
      <Divider />
      <AuctionStats />
      <Divider />
      <InventoryStats inventorystats={inventorystats} />
      {/*
      <div className="grid lg:grid-cols-3 mt-4 ">
        {favourites && <Favourites favourites={favourites} />}
      </div> */}
    </>
  );
}
