const config = {
  org: process.env.REACT_APP_SPORTIENDA_ORG,
  publicWebsite: process.env.REACT_APP_PUBLIC_WEB || "https://sportienda.com",
  features: {
    publicCalculator: !!process.env.REACT_APP_ENABLE_PUBLIC_CALCULATOR,
  },

  storage: {
    images: "https://storage.googleapis.com/sportienda-bicycle-images",
  },
};
export default config;
export const publicWebsite = config.publicWebsite;
