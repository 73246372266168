import { XCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Link, Route, Routes, useParams } from "react-router-dom";
import Loading from "../../../components/molecules/Loading";
import Form, { FormWrapper } from "../../../components/organisms/CMSForm";
import { ItemList } from "./List";

import type { ItemSchema } from "@app/types";

export function ItemEdit({ schema }: { schema: ItemSchema }) {
  const params = useParams();
  const paramId = params[`${schema.id}ID`] || params.id;
  const [updateItem] = schema.useUpdateItemMutation();
  const [saving, setSaving] = useState(false);
  const { loading, data, refetch } = schema.useItemQuery({
    variables: {
      id: paramId ? (schema.idIsNumber ? parseInt(paramId) : paramId) : null,
    },
  });

  const item = schema.editInitialValues;
  const itemData = schema.parseItem(data);

  if (itemData) {
    Object.keys(item).forEach((key) => {
      item[key] = itemData[key];
    });
  } else {
    return <Loading />;
  }
  const id = itemData.id;
  const submit = async (data: any) => {
    setSaving(true);
    await updateItem({ variables: { id: id, patch: data } });
    setSaving(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <FormWrapper>
        <div className="flex justify-end">
          <Link
            to=".."
            className="text-sportgreen-600 hover:text-sportgreen-900"
          >
            <XCircleIcon className="h-6 w-6" />
          </Link>
        </div>

        <Form
          title={`Edit ${schema.name}`}
          form={schema.editForm}
          data={item}
          extraData={itemData}
          dictionary={{}}
          refetch={refetch}
          loading={saving}
          save={submit}
          itemId={id}
        />
      </FormWrapper>
    </>
  );
}

export function ItemDetails({ schema }: { schema: ItemSchema }) {
  const params = useParams();
  console.log("params", params);
  const paramId = params[`${schema.id}ID`] || params.id;
  console.log("params", params, paramId);
  const { loading, data, refetch } = schema.useItemQuery({
    variables: {
      id: paramId ? (schema.idIsNumber ? parseInt(paramId) : paramId) : null,
    },
  });

  const item = schema.editInitialValues;
  const itemData = schema.parseItem(data);
  console.log("itemData", itemData);
  const id = itemData.id;
  if (itemData) {
    Object.keys(item).forEach((key) => {
      item[key] = itemData[key];
    });
  } else {
    return <Loading />;
  }

  const parentFilter: { [key: string]: string } = {};

  if (schema.parentId && id) {
    parentFilter[schema.parentId] = id;
  }
  console.log("parentFilter", parentFilter);
  const defaultValues: any = { data: {}, extraData: {} };
  if (schema.parentId) {
    defaultValues["data"][schema.parentId] = id;
  }
  if (schema.parentObjectName) {
    defaultValues["extraData"][schema.parentObjectName] = {
      name: item.name,
    };
  }
  return (
    <div className="my-4 border-gray-200">
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h2 className="text-xs font-semibold leading-6 text-gray-900 uppercase">
              {schema.name}
            </h2>
            <h3 className="text-xl font-semibold leading-6 text-gray-900">
              {item.name || item.id}
            </h3>
          </div>
          <div className="ml-4 mt-2 flex-shrink-0">
            <Link
              to="edit"
              className="relative inline-flex items-center rounded-md bg-sportgreen-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sportgreen-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sportgreen-600"
            >
              Edit
            </Link>
          </div>
        </div>
        <div className="mt-6 border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            {schema.editForm
              ?.filter((field) => field.id != "name")
              .map((field) => {
                return (
                  <div
                    key={field.id}
                    className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                  >
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      {field.name}
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {item[field.id]}
                    </dd>
                  </div>
                );
              })}
          </dl>
        </div>
      </div>
      {schema.children?.map((s) => (
        <div key={s.id} className="mt-2">
          <ItemList
            schema={s}
            urlPrefix={s.id}
            defaultValues={defaultValues}
            parentFilter={parentFilter}
          />
        </div>
      ))}
    </div>
  );
}

export function RecursiveRoute({
  schema,
  path,
}: {
  schema: ItemSchema;
  path?: string;
}) {
  return (
    <Routes>
      <Route path={`:${schema.id}ID/*`}>
        <Route index element={<ItemDetails schema={schema} />} />
        <Route path="edit" element={<ItemEdit schema={schema} />} />

        {schema.children?.map((s) => (
          <Route
            key={s.id}
            path={`${s.id}/*`}
            element={<RecursiveRoute schema={s} />}
          />
        ))}
      </Route>
      <Route index element={<ItemList schema={schema} />} />
    </Routes>
  );
}
