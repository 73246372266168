export function StickyActionBar({
  children,
  header,
  errors,
}: {
  children: React.ReactNode;
  header?: React.ReactNode;
  errors?: React.ReactNode;
}) {
  return (
    <div className="sticky top-0 z-50 mb-4 bg-white border-b border-gray-200 shadow-sm">
      {header && <div className="py-2 px-2 mx-auto">{header}</div>}
      <div className="flex items-center justify-end px-2 pb-4 mx-auto max-w-7xl">
        {errors}
        <div className="flex items-center justify-end space-x-4">
          {children}
        </div>
      </div>
    </div>
  );
}
