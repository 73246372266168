import { useAuth0 } from "@auth0/auth0-react";

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { trackEvent } from "../../utils/stats";

import {
  Button,
  CheckField,
  SelectField,
  TextField,
} from "../molecules/inputs";
import { BicycleSearch } from "../molecules/BicycleSearch";

import {
  CreatePriceQueryMutation,
  useBicyclesByBrandFamilyAndYearQuery,
  useBrandFamilyByYearQuery,
  useBrandYearsQuery,
  useBrandsQuery,
  useCreatePriceQueryMutation,
} from "@app/graphql";
import {
  CreateOutletMutation,
  CreateTradeInMutation,
  useCreateOutletMutation,
  useCreateTradeInMutation,
} from "@app/graphql/pim";

const numberOrNull = (i: string) => (i ? parseInt(i) : null);

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: "base",
});

const orderByName = (arr: any[]) =>
  [...arr].sort((a, b) => collator.compare(a.name, b.name));

type BrandInputProps = {
  loading?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};
function BrandInput(props: BrandInputProps) {
  let options = [[null, "Velg merke"]];

  const { data, loading } = useBrandsQuery({
    context: { clientName: "public" },
  });

  if (data?.brands?.nodes) {
    const newOptions = orderByName(data.brands.nodes).map(({ id, name }) => [
      id,
      name,
    ]);
    options = [...options, ...newOptions];
  }

  return (
    <SelectField
      {...props}
      disabled={loading || props.loading}
      options={options as any}
    />
  );
}

type YearInputProps = {
  brandId: number;
  loading?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

type Options = [number | null, string][];

function YearInput({ brandId, ...props }: YearInputProps) {
  let options: Options = [[null, "Velg år"]];

  const { data, loading } = useBrandYearsQuery({
    variables: {
      id: brandId,
    },
    skip: !brandId,
    context: { clientName: "public" },
  });

  if (data?.brand?.years) {
    const newOptions = data.brand.years.nodes.map((year) => [
      year,
      String(year),
    ]) as Options;
    options = [...options, ...newOptions];
  }

  return (
    <SelectField
      {...props}
      disabled={loading || props.loading || !brandId}
      options={options as any}
    />
  );
}

type FamilyInputProps = {
  year: number;
  brandId: number;
  loading?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};
function FamilyInput({ year, brandId, ...props }: FamilyInputProps) {
  let options = [[null, "Velg sykkelmodell"]];

  const { data, loading } = useBrandFamilyByYearQuery({
    variables: {
      year,
      brandId,
    },
    skip: !brandId || !year,
    context: { clientName: "public" },
  });

  if (data?.brandFamiliesByYear?.nodes) {
    const newOptions = orderByName(data.brandFamiliesByYear.nodes).map(
      ({ id, name }) => [id, name]
    );
    options = [...options, ...newOptions];
  }

  return (
    <SelectField
      {...props}
      disabled={loading || props.loading || !year || !brandId}
      options={options as any}
    />
  );
}

type BikeInputProps = {
  year: number;
  brandFamilyId: number;
  loading?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

function BikeInput({ year, brandFamilyId, ...props }: BikeInputProps) {
  let options: Options = [[null, "Velg sykkel"]];

  const { data, loading } = useBicyclesByBrandFamilyAndYearQuery({
    variables: {
      year,
      brandFamilyId,
    },
    skip: !brandFamilyId || !year,
    context: { clientName: "public" },
  });

  if (data?.models?.nodes) {
    const newOptions: Options = [];
    data.models.nodes.map(({ bicycles }) =>
      orderByName(bicycles?.nodes || []).map(({ id, name }) =>
        newOptions.push([id, name])
      )
    );
    options = [...options, ...newOptions];
  }

  return (
    <SelectField
      {...props}
      disabled={loading || props.loading || !year || !brandFamilyId}
      options={options as any}
    />
  );
}

type FormProps = {
  type?: "tradein" | "outlet" | "sell" | undefined;
  compact?: boolean;
};

type Input = {
  required: boolean;
  label: string;
  component: any;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void | undefined;
  brandId?: number | undefined | null;
  year?: number | undefined | null;
  brandFamilyId?: number | undefined | null;
  bicycleId?: number | undefined | null;
  bicycleName?: string | undefined | null;
  type?: string | undefined | null;
  value?: string | undefined | null;
  onInput?: (e: any) => void | undefined;
  disabled?: boolean | undefined | null;
  description?: string | undefined | null;
};

function InputComponent({
  loading,
  input,
}: {
  loading: boolean;
  input: Input;
}) {
  const { label, component: Component, ...props } = input;
  if (props.type === "checkbox") {
    return (
      <div>
        <div className="flex items-center">
          <Component {...props} disabled={loading || props.disabled} />
          <label className="ml-2 text-sm text-sportblack">{label}</label>
        </div>
        {props.description && (
          <div className="mt-1 text-xs text-sportblack">
            {props.description}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2">
        <Component {...props} disabled={loading || props.disabled} />
      </div>
    </div>
  );
}

export default function Form({ type = "sell", compact }: FormProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentError, setError] = useState(null as string | null);
  const [accept, setAccept] = useState(type !== "sell");
  const [showSearch, setShowSearch] = useState(true);
  const [form, setForm] = useState({
    ownerEmail: "",
    brandId: null,
    year: null,
    brandFamilyId: null,
    bicycleId: null,
    bicycleName: null,
    newsletter: false,
  } as {
    ownerEmail: string;
    brandId: number | null;
    year: number | null;
    brandFamilyId: number | null;
    bicycleId: number | null;
    bicycleName: string | null;
    newsletter: boolean;
  });
  const { user } = useAuth0();

  useEffect(() => {
    if (user && user.email && (!form.ownerEmail || !form.ownerEmail.length)) {
      setForm({ ...form, ownerEmail: user.email });
    }
  }, [user]);

  const updateForm = (k: string, v: any) =>
    setForm((prevForm) => ({ ...prevForm, [k]: v }));
  const applyForm = (v: any) => setForm((prevForm) => ({ ...prevForm, ...v }));

  const [submitTradein] = useCreateTradeInMutation();
  const [submitOutlet] = useCreateOutletMutation();
  const [submitPublic] = useCreatePriceQueryMutation({
    context: { clientName: "public" },
  });

  const mutationMap = {
    tradein: submitTradein,
    outlet: submitOutlet,
    sell: submitPublic,
  };

  const submit = type ? mutationMap[type] : submitPublic;

  const submitForm = async () => {
    const { ownerEmail, bicycleId, newsletter } = form;

    trackEvent("Calculator Submit", { bicycleId: bicycleId });
    if (bicycleId === null) {
      throw new Error("Missing bicycleId");
    }

    const { data } = await submit({
      variables: { ownerEmail, bicycleId, newsletter },
    });
    let createdId;
    if (type === "tradein") {
      createdId = (data as CreateTradeInMutation).createTradeIn
        ?.bicyclePriceQuery?.id;
    } else if (type === "outlet") {
      createdId = (data as CreateOutletMutation).createOutlet?.bicyclePriceQuery
        ?.id;
    } else {
      createdId = (data as CreatePriceQueryMutation).createPriceQuery
        ?.bicyclePriceQuery?.id;
    }

    if (!createdId) {
      throw new Error("Missing data from response");
    }
    if (type === "tradein") {
      navigate(`/tradein/${createdId}`);
    } else if (type === "outlet") {
      navigate(`/outlet/${createdId}`);
    } else {
      navigate(`/sell/${createdId}`);
    }
  };
  //React.FunctionComponent<SelectionProps | YearInputProps | FamilyInputProps | BikeInputProps>

  const emailInput: Input[] = [
    {
      required: true,
      label: "E-post",
      type: "email",
      value: form.ownerEmail,
      component: TextField,
      onInput: (e: any) => updateForm("ownerEmail", e.target.value),
    },
    {
      required: false,
      label: "Meld meg inn i Team-Sportienda",
      description:
        "Få 500,- i rabatt på første sykkel og motta nyhetsbrev med tips og råd",
      type: "checkbox",
      component: CheckField,
      onInput: (e: any) => updateForm("newsletter", e.target.checked),
    },
  ];

  const inputs: Input[] = [
    {
      required: true,
      label: "Merke",
      component: BrandInput,
      onChange: (e: any) => {
        trackEvent("Calculator Select Brand", {
          brandId: e.target.value,
          brand: e.target.options[e.target.selectedIndex].text,
        });
        applyForm({
          brandId: numberOrNull(e.target.value),
          year: null,
          brandFamilyId: null,
          bicycleId: null,
        });
      },
    },
  ];
  if (!compact || form["brandId"]) {
    inputs.push(
      {
        required: true,
        label: "Årsmodell",
        component: YearInput,
        brandId: form.brandId,
        onChange: (e: any) => {
          trackEvent("Calculator Select Year", { year: e.target.value });
          applyForm({
            year: numberOrNull(e.target.value),
            brandFamilyId: null,
            bicycleId: null,
          });
        },
      },
      {
        required: true,
        label: "Sykkelmodell",
        component: FamilyInput,
        brandId: form.brandId,
        year: form.year,
        onChange: (e: any) => {
          trackEvent("Calculator Select BrandFamily", {
            brandFamilyId: e.target.value,
            brandFamily: e.target.options[e.target.selectedIndex].text,
          });
          applyForm({
            brandFamilyId: numberOrNull(e.target.value),
            bicycleId: null,
          });
        },
      },
      {
        required: true,
        label: "Sykkel",
        component: BikeInput,
        brandFamilyId: form.brandFamilyId,
        year: form.year,
        onChange: (e: any) => {
          trackEvent("Calculator Select Bicycle", {
            bicycleId: e.target.value,
            bicycle: e.target.options[e.target.selectedIndex].text,
          });
          updateForm("bicycleId", numberOrNull(e.target.value));
          updateForm(
            "bicycleName",
            e.target.options[e.target.selectedIndex].text
          );
        },
      }
    );

    if (type === "sell") {
      emailInput.map((input: Input) => inputs.push(input));
    }
  }
  const onCheckboxToggle = (ev: any) => {
    setAccept(ev.target.checked);
  };

  const onSubmit = async (ev: any) => {
    ev.preventDefault();

    if (loading) return;
    try {
      setLoading(true);
      setError(null);
      await submitForm();
    } catch (e) {
      setError("Feil under sending av skjema");
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <form onSubmit={onSubmit}>
      <div className="mb-8 space-y-4">
        {showSearch ? (
          <>
            <BicycleSearch
              bicycleId={form.bicycleId}
              bicycleName={form.bicycleName}
              setBicycleId={(bicycleId) => updateForm("bicycleId", bicycleId)}
              setBicycleName={(bicycleName) =>
                updateForm("bicycleName", bicycleName)
              }
            />
            <>
              {form.bicycleId &&
                type === "sell" &&
                emailInput.map((input: Input) => (
                  <InputComponent
                    key={input.label}
                    loading={loading}
                    input={input}
                  />
                ))}
            </>
          </>
        ) : (
          <>
            {inputs.map((input) => (
              <InputComponent
                key={input.label}
                loading={loading}
                input={input}
              />
            ))}
          </>
        )}
        <div className="flex items-center justify-center">
          <button
            className="text-sportgreyweb text-xs mt-1 hover:underline"
            onClick={(e) => {
              e.preventDefault();
              setShowSearch(!showSearch);
            }}
          >
            {showSearch ? "avansert søk" : "fritekst søk"}
          </button>
        </div>

        {currentError && (
          <div className="bg-red-100 p-2 text-center text-red-500">
            {String(currentError)}
          </div>
        )}
      </div>

      <div className="-mx-4 bg-gray-100">
        <div className="flex items-center justify-between py-2 px-4">
          {type === "sell" && (
            <label className="flex items-center space-x-2">
              <input type="checkbox" onChange={onCheckboxToggle} />
              <span>
                <span>Aksepter </span>
                <Link to="/privacy" className="underline" target="_blank">
                  vilkårene
                </Link>
              </span>
            </label>
          )}
          <div>
            <Button
              type="submit"
              disabled={
                loading ||
                !accept ||
                !form.bicycleId ||
                (type === "sell" && !form.ownerEmail)
              }
              className={undefined}
            >
              {loading ? "Sender..." : "Fortsett"}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
