import { gql, useQuery } from "@apollo/client";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ChevronDownIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../components/molecules/Loading";
import Pagination from "../../components/organisms/Pagination";
import { dateTimeFormat } from "../../utils/dates";
import { classNames } from "../../utils/dom";
import useEnum from "../../utils/enum";

/* eslint-disable react/prop-types */

const PAGESIZE = 30;

const formatDate = (date) =>
  typeof date === "string" ? dateTimeFormat(date) : "";

const sortOptions = [
  { name: "Most Popular", href: "#", current: true },
  { name: "Best Rating", href: "#", current: false },
  { name: "Newest", href: "#", current: false },
];

const tableColumns = [
  {
    label: "PP#",
    value: "sequence",
  },
  {
    label: "Sykkel",
    value: "bicycleName",
  },
  {
    label: "Rammenummer",
    value: "bicycleFrameNumber",
  },
  {
    label: "Kontraktsdato",
    value: (data) => formatDate(data.contractSigned),
  },
  {
    label: "Åpent for salg",
    value: (data) => formatDate(data.quarantineEnd),
  },
  {
    label: "BankID signatur verifisert (personnummer)",
    value: (data) => (
      <>
        {data.contractSigned ? (
          <>
            <CheckCircleIcon
              className="inline-block h-5 w-5 text-sportgreen"
              aria-hidden="true"
            />{" "}
            ({data.signedName} ({data.signedBdate}))
          </>
        ) : (
          <>Ikke signert</>
        )}
      </>
    ),
  },
];

const LIST_QUERY = gql`
  query ListQuery(
    $statuses: [PurchaseProtocolStatus!]
    $pagesize: Int
    $offset: Int
    $search: String = "%"
  ) {
    purchaseProtocols(
      filter: {
        and: {
          status: { in: $statuses }
          or: [
            { bicycleFrameNumber: { likeInsensitive: $search } }
            { bicycleName: { likeInsensitive: $search } }
            { signedName: { likeInsensitive: $search } }
          ]
        }
      }
      orderBy: [LAST_UPDATED_DESC, QUARANTINE_END_DESC, CONTRACT_SIGNED_DESC]
      first: $pagesize
      offset: $offset
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        bicyclePriceQueryId
        sequence
        status
        created
        contractSigned
        quarantineStart
        quarantineEnd
        releasedTime
        canceledTime
        canceledComment
        lastUpdated
        bicycleName
        bicycleFrameNumber
        signedName
        signedAddress
        signedEmail
        signedPhone
        signedBdate
        authType
        certIssuer
        certSubject
      }
    }
  }
`;

function Filters({ listFiler, setFiler, search, setSearch, addQuery }) {
  const enumValues = useEnum("PurchaseProtocolStatus");

  return (
    <Disclosure
      as="section"
      aria-labelledby="filter-heading"
      className="relative z-10 grid  w-full items-center border-b border-gray-200"
    >
      <h2 id="filter-heading" className="sr-only">
        Filters
      </h2>
      <div className="relative col-start-1 row-start-1 py-4">
        <div className="mx-auto flex w-full space-x-6 divide-x divide-gray-200 text-sm ">
          <div className="ml-5 mt-2">
            <Disclosure.Button className="group flex items-center font-medium text-gray-700">
              <FunnelIcon
                className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              Filters
            </Disclosure.Button>
          </div>
          <div className="grow pl-6">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search-field"
                className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
                placeholder="Search"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                type="search"
                name="search"
              />
            </div>
          </div>
        </div>
      </div>
      <Disclosure.Panel className="border-t border-gray-200 py-10">
        <div className="mx-auto grid w-full grid-cols-2 gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
          <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
            <fieldset>
              <legend className="block font-medium">Status</legend>
              <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                {enumValues &&
                  enumValues.map((option) => (
                    <div
                      key={`status-mobile-${option}`}
                      className="flex items-center"
                    >
                      <input
                        id={`status-mobile-${option.id}`}
                        type="checkbox"
                        value={option.id}
                        onChange={() =>
                          listFiler.includes(option.id)
                            ? setFiler(
                                listFiler.filter((value) => value !== option.id)
                              )
                            : setFiler([option.id].concat(listFiler))
                        }
                        checked={listFiler.includes(option.id)}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label
                        htmlFor={`status-mobile-${option.id}`}
                        className="ml-3 text-sm text-gray-500"
                      >
                        {option.name}
                      </label>
                    </div>
                  ))}
              </div>
            </fieldset>
          </div>
        </div>
      </Disclosure.Panel>
      <div className="col-start-1 row-start-1 py-4">
        <div className="mx-auto flex w-full justify-end px-4 sm:px-6 lg:px-8">
          <Menu as="div" className="relative inline-block">
            <div className="flex">
              <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                Sort
                <ChevronDownIcon
                  className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {sortOptions.map((option) => (
                    <Menu.Item key={option.name}>
                      {({ active }) => (
                        <a
                          href={option.href}
                          className={classNames(
                            option.current
                              ? "font-medium text-gray-900"
                              : "text-gray-500",
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          {option.name}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </Disclosure>
  );
}

export default function Bikes() {
  // const params = useParams()
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [listFiler, setFiler] = useState([
    "NEW",
    "CANCELED",
    "QUARANTINED",
    "RELEASED",
  ]);

  const { data, loading } = useQuery(LIST_QUERY, {
    variables: {
      statuses: listFiler,
      pagesize: PAGESIZE,
      offset: page * PAGESIZE,
      search: search ? `%${search}%` : undefined,
    },
  });

  const protocols = data?.purchaseProtocols?.nodes || [];

  const pageInfo = data?.purchaseProtocols?.pageInfo || {
    hasNextPage: false,
    hasPreviousPage: false,
  };

  return (
    <>
      <div className="flex flex-col">
        <Filters
          listFiler={listFiler}
          setFiler={setFiler}
          search={search}
          setSearch={setSearch}
        />

        <div className="relative -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          {loading && (
            <div className="absolute inset-0 bg-white/50">
              <Loading />
            </div>
          )}

          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {tableColumns.map(({ label }, index) => (
                      <th
                        key={index}
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        {label}
                      </th>
                    ))}

                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {protocols &&
                    protocols.map((bicylce, listIdx) => (
                      <tr
                        key={`${bicylce.id}-${listIdx}`}
                        className={classNames(
                          listIdx % 2 === 0 ? "bg-white" : "bg-gray-50",
                          `text-${bicylce?.status?.toLowerCase()}`
                        )}
                      >
                        {tableColumns.map(({ value }, index) => (
                          <td
                            key={index}
                            className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            {typeof value === "function"
                              ? value(bicylce)
                              : bicylce[value]}
                          </td>
                        ))}

                        <td className="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
                          <Link
                            to={`/pricequery/${bicylce.bicyclePriceQueryId}`}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Prisforespørsel
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <Pagination
                totalCount={protocols.length}
                page={page}
                setPage={setPage}
                pageSize={PAGESIZE}
                pageInfo={pageInfo}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
