import { gql, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { usePaginationTableState } from "../../../../hooks/usePaginationTable";

const QUERY = gql`
  query GetBicyclePriceQueries(
    $filter: BicyclePriceQueryFilter
    $condition: BicyclePriceQueryCondition
    $pagesize: Int
    $offset: Int
    $orderBy: [BicyclePriceQueriesOrderBy!]
  ) {
    bicyclePriceQueries(
      filter: $filter
      condition: $condition
      first: $pagesize
      offset: $offset
      orderBy: $orderBy
    ) {
      nodes {
        id
        bicycleId
        bicycle {
          name
          year
        }
        created
        lastUpdated
        status
        type
        ownerEmail
        ownerFirstName
        ownerLastName
        currentPrice
        scoreDiscount
        calculatedSportiendaScore
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export function useBicyclePriceQueries(pagesize, type) {
  const { user } = useAuth0();
  //make type upper case

  const { filter, offset, orderBy } = usePaginationTableState({
    filters: {
      name: (v) => ({ likeInsensitive: `%${v}%` }),
      status: (v) => ({ equalTo: v }),
      type: type ? { equalTo: type?.toUpperCase() } : (v) => ({ equalTo: v }),
    },
  });

  const result = useQuery(QUERY, {
    variables: {
      filter,
      pagesize,
      offset,
      orderBy,
      condition: type ? { orgId: user?.org_id } : {},
    },
    nextFetchPolicy: "cache-and-network",
  });

  var pricequeries = result.data?.bicyclePriceQueries?.nodes ?? [];
  if (!pricequeries) {
    pricequeries = [];
  }

  const { totalCount = 0 } = result?.data?.bicyclePriceQueries ?? {};

  return { ...result, pricequeries, totalCount };
}
