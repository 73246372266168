import { useStatsPurchaseQuery } from "@app/graphql/pim";
import DataTable, {
  FieldDescriptionList,
} from "../../components/molecules/DataTable";
import Loading from "../../components/molecules/Loading";
import NotFound from "../../components/organisms/notfound";

const statsPurchaseFields = {
  type: { name: "type", type: "string" },
  status: {
    name: "status",
    type: "link",
    config: { link: "/pricequery/", linkPostfix: "id" },
  },
  intakeStatus: {
    name: "intake",
    type: "link",
    config: { link: "/intake/", linkPostfix: "intakeId" },
  },
  bicycleName: {
    name: "Bicycle Name",
    type: "link",
    config: { link: "/bicycles/", linkPostfix: "bicycleId" },
  },
  sku: { name: "sku", type: "string" },
  bicycleType: { name: "Bike Type", type: "string" },
  electric: { name: "EL", type: "boolean" },
  retailPriceNok: { name: "Retail", type: "currency" },

  calculatedSportiendaScore: {
    name: "Score",
    type: "number",
  },
  depreciatedValue: { name: "Value", type: "currency" },
  calculatedOfferPrice: { name: "Calc Offer", type: "currency" },
  offeredPrice: { name: "Offered", type: "currency" },
} as FieldDescriptionList;

export default function IntakeStats() {
  const { data, loading } = useStatsPurchaseQuery();
  if (loading) return <Loading />;
  if (!data || !data?.statsPurchases) return <NotFound />;

  const { nodes: purchase } = data.statsPurchases;

  return (
    <DataTable
      tableName="Purchase Stats"
      fields={statsPurchaseFields}
      data={purchase}
    />
  );
}
