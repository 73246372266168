import { useRef } from "react";
import { Link } from "react-router-dom";
import { dateTimeFormat } from "../../../utils/dates";
import { classNames } from "../../../utils/dom";
import { useSelectAll } from "../../../utils/selection";

// import FilterText from '../../../components/molecules/FilterText'
import FilterSingle from "../../../components/molecules/FilterSingle";
import Pagination from "../../../components/molecules/Pagination";
import SelectedRows from "../../../components/molecules/SelectedRows";
import SortableColumn from "../../../components/molecules/SortableColumn";
import useEnum from "../../../utils/enum";
import { useInventories } from "./hooks/useInventory";
import BulkActions from "./partials/BulkActions";
const PAGESIZE = 20;

export default function IntakeList() {
  const STATUS = useEnum("InventoryStatus");
  const { data, inventories, totalCount } = useInventories(PAGESIZE);
  const selectAllRef = useRef();
  const { selected, setSelected, selection } = useSelectAll(
    selectAllRef,
    inventories
  );

  return (
    <div className="px-4 mb-3">
      <div className="grid grid-cols-1">
        <div className="row-start-1 flex flex-wrap justify-start mb-3 text-sm">
          {/* <div className="relative mr-3 mb-3">
            <FilterText name="name" placeholder={t('Search inventories')} />
          </div> */}
          <div className="mr-3 mb-3">
            <FilterSingle name="status" values={STATUS} />
          </div>
        </div>
        <div className="row-start-1 flex justify-end mb-6 text-sm">
          <BulkActions selected={selected} selection={selection} data={data} />
        </div>
      </div>
      <SelectedRows
        selected={selected}
        setSelected={setSelected}
        selection={selection}
        totalCount={totalCount}
        pageSize={PAGESIZE}
      />
      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead>
            <tr className="bg-gray-50 text-left text-xs text-gray-900 uppercase tracking-wider">
              <th className="sticky left-0 z-10 px-4 py-4 bg-inherit font-medium">
                <input
                  name="select-all"
                  type="checkbox"
                  className="mr-4 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  value="current-page"
                  checked={
                    selected.length > 0 &&
                    (selected.length >= PAGESIZE ||
                      selected.length === totalCount)
                  }
                  onChange={setSelected}
                  ref={selectAllRef}
                />
                SKU
              </th>
              <th className="px-4 py-4 font-medium">Annonsetype</th>
              <th className="px-4 py-4 font-medium">Objekt</th>
              <th className="px-4 py-4 font-medium">Name</th>

              <th className="px-4 py-4 font-medium">
                <SortableColumn field="STATUS" />
              </th>

              <th className="px-4 py-4 font-medium">
                <SortableColumn field="CREATED" />
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {inventories.map((inventory) => (
              <tr
                key={inventory.id}
                className={classNames(
                  { "bg-indigo-50": selected.includes(inventory.id) },
                  "text-sm text-gray-500 whitespace-nowrap bg-white hover:bg-indigo-50 border-t border-gray-100"
                )}
              >
                <td className="sticky left-0 z-10 max-w-xs sm:max-w-sm px-4 py-3 bg-inherit font-medium text-gray-900 text-left text-ellipsis overflow-hidden">
                  {selected.includes(inventory.id) && (
                    <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                  )}
                  <input
                    name="intakeform"
                    type="checkbox"
                    className="mr-4 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    value={inventory.id}
                    checked={selected.includes(inventory.id)}
                    onChange={setSelected}
                  />
                  {inventory?.bicycleIntakeForm?.sku}
                </td>
                <td className="px-4 py-3">{inventory.type}</td>
                <td className="px-4 py-3">{inventory.objectType}</td>
                <td className="px-4 py-3">
                  <Link to={inventory.id}>{inventory?.name}</Link>
                </td>

                <td className="px-4 py-3">{inventory?.status}</td>

                <td className="px-4 py-3">
                  {dateTimeFormat(inventory.created)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination totalCount={totalCount} pageSize={PAGESIZE} />
    </div>
  );
}
